import React, { useState, useRef } from 'react';
import TooltipDefault from '../../../images/icons/svg/tooltip-default.svg';
import TooltipHover from '../../../images/icons/svg/tooltip-hover.svg';
import TooltipSelected from '../../../images/icons/svg/tooltip-selected.svg';
import ToolboxDimple from '../../../images/icons/svg/toolbox-dimple.svg';
import CheckmarkWhite from '../../../images/icons/svg/checkmark-white.svg';
import CrossGrey from '../../../images/icons/svg/cross-grey.svg';

import "./Tooltip.scss";

export const Tooltip = props => {

    const { type } = props;

    const [imgSrc, setImgSrc] = useState(TooltipDefault);
    const [active, setActive] = useState(false);
    const tooltipRef = useRef(null);

    const mouseLeave = () => {
        if (!active) {
            setImgSrc(TooltipDefault);
        }
    }

    const onClick = () => {
        tooltipRef.current.focus();
    }

    const onBlur = () => {
        setActive(false);
        setImgSrc(TooltipDefault);
    }

    const onFocus = () => {
        setActive(true);
        setImgSrc(TooltipSelected)
    }

    const mouseEnter = () => {
        if (!active) {
            setImgSrc(TooltipHover);
        }
    }

    const renderContent = () => {
        if (type === "permissions") {
            const { allowedPermissions } = props;
            const { disallowedPermissions } = props;
            return (
                <div className="list">
                    {allowedPermissions.map((permission, index) => {
                        return (
                            <li key={index} className="item item-allowed">
                                <img src={CheckmarkWhite} />
                                <div className="text">
                                    {permission}
                                </div>
                            </li>
                        );
                    })}
                    {disallowedPermissions.map((permission, index) => {
                        return (
                            <li key={index} className="item item-disallowed">
                                <img src={CrossGrey} />
                                <div className="text">
                                    {permission}
                                </div>
                            </li>
                        );
                    })}
                </div>
            )
        }
    }

    return (
        <div className="tooltip-container">
            <img
                className="tooltip"
                tabIndex="-1"
                ref={tooltipRef}
                onMouseEnter={mouseEnter}
                onMouseLeave={mouseLeave}
                onFocus={onFocus}
                onBlur={onBlur}
                onClick={onClick}
                src={imgSrc}
            />
            <img className={`tooltip-dimple${!active ? ` hidden` : ``}`} src={ToolboxDimple} />
            <div className={`content${!active ? ` hidden` : ``}`}>
                {renderContent()}
            </div>
        </div>
    )
}