import React from "react";
import PropTypes from "prop-types";
import styles from "./DocumentReferenceIndicators.module.scss";
import httpClient from "../../lib/HttpClient";

import referenceIcon from '../../images/icons/16px/link.svg';

/* UI Kit */
import { Uikon } from "@uik";
import "@uik/styles.css";
import "../../font.scss";

/* Variables */

class DocumentReferenceIndicators extends React.Component {
  constructor() {
    super();

    this.state = {
      references: [],
      referenceBlockId: null
    };
  }

  componentWillMount = () => {
    this.loadDocumentReferences();
  };

  componentDidMount = () => {
    document.addEventListener("reload_references", e => {
      this.loadDocumentReferences();
    });
  };

  loadDocumentReferences = () => {
    const { docId, pageNumber } = this.props;

    // Retrieve the document references.
    httpClient
      .get(`/documents/${docId}/references/${pageNumber}.json`)
      .then(response => {
        this.setState({
          references: response.data
        });
      });
  };

  shouldComponentUpdate() {
    return true;
  }

  highlightBlock = blockId => {
    const range = blockId.split("--");
    const firstElement = document.getElementById(range[0]);
    const lastElement = document.getElementById(range[range.length - 1]);

    let blockIdentifier = "";

    let collection = [];
    collection.push(firstElement);

    let currentElement = firstElement;

    if (currentElement !== null && currentElement.innerHTML) {
      let htmlstring = currentElement.innerHTML;
      htmlstring = htmlstring.trim
        ? htmlstring.trim()
        : htmlstring.replace(/^\s+/, "");

      if (htmlstring !== "") {
        currentElement.classList.add("referencedText");
        blockIdentifier = currentElement.id;
      }
    }

    // Loop through all elements until we reach the end and store it in our collection.
    while (
      currentElement !== null &&
      currentElement !== lastElement &&
      typeof currentElement.nextSibling !== "undefined"
    ) {
      if (currentElement !== null) {
        collection.push(currentElement.nextSibling);
        currentElement = currentElement.nextSibling;

        if (currentElement !== null && currentElement.innerHTML) {
          let htmlstring = currentElement.innerHTML;
          htmlstring = htmlstring.trim
            ? htmlstring.trim()
            : htmlstring.replace(/^\s+/, "");

          if (htmlstring !== "") {
            currentElement.classList.add("referencedText");
          }
        }
      }
    }
  };

  unHighlightBlock = blockId => {
    const documentReferencesClosed =
      document.getElementById("documentReferencePanel") === null;

    if (documentReferencesClosed || this.state.referenceBlockId !== blockId) {
      const range = blockId.split("--");
      const firstElement = document.getElementById(range[0]);
      const lastElement = document.getElementById(range[range.length - 1]);

      let blockIdentifier = "";

      let collection = [];
      collection.push(firstElement);

      let currentElement = firstElement;

      if (currentElement !== null && currentElement.innerHTML) {
        let htmlstring = currentElement.innerHTML;
        htmlstring = htmlstring.trim
          ? htmlstring.trim()
          : htmlstring.replace(/^\s+/, "");

        if (htmlstring !== "") {
          currentElement.classList.remove("referencedText");
          blockIdentifier = currentElement.id;
        }
      }

      // Loop through all elements until we reach the end and store it in our collection.
      while (
        currentElement !== null &&
        currentElement !== lastElement &&
        typeof currentElement.nextSibling !== "undefined"
      ) {
        if (currentElement !== null) {
          collection.push(currentElement.nextSibling);
          currentElement = currentElement.nextSibling;

          if (currentElement !== null && currentElement.innerHTML) {
            let htmlstring = currentElement.innerHTML;
            htmlstring = htmlstring.trim
              ? htmlstring.trim()
              : htmlstring.replace(/^\s+/, "");

            if (htmlstring !== "") {
              currentElement.classList.remove("referencedText");
            }
          }
        }
      }
    }
  };

  clickedBlock = (blockId, id) => {
    const unhighlightElements = document.getElementsByClassName(
      "referencedText"
    );

    while (unhighlightElements[0]) {
      unhighlightElements[0].classList.remove("referencedText");
    }

    this.highlightBlock(blockId);

    this.setState({ referenceBlockId: blockId });

    this.props.loadReference(blockId, id);
  };

  render() {
    const references = this.state.references;

    return (
      <div className={styles.indicatorContainer}>
        {references &&
          references.map((reference, index) => (
            <div
              key={reference.id}
              className={styles.documentPageCommentIndicators}
              onMouseOver={() =>
                this.highlightBlock(reference.block_identifier)
              }
              onMouseOut={() =>
                this.unHighlightBlock(reference.block_identifier)
              }
              onClick={() =>
                this.clickedBlock(reference.block_identifier, reference.id)
              }
            >
              <div className={styles.referenceIndicatorContainer}>
                <span className={styles.consersationIcon}>
                  <img src={referenceIcon} />
                </span>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

DocumentReferenceIndicators.propTypes = {
  docId: PropTypes.number,
  pageNumber: PropTypes.number,
  loadReference: PropTypes.func
};

export default DocumentReferenceIndicators;
