/* Libraries */
import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import axios from "../../lib/axios-client";
import { Line } from "rc-progress";
import security from "../../services/Security";
import { apiUrl } from "../../lib/axios-client";

/* Assets */
import Warning from "../../images/icons/png/warning.svg";
import Button from '../Common/Buttons/ButtonPrimary';
import uploadIcon from "../../images/icons/png/upload.png"
import noUploadIcon from "../../images/icons/png/noUpload.png"

/* Styles */
import styles from "./DocumentAttachmentsPanel.module.scss";

/* UI Kit */
import {
  Uikon,
  UikDropdown,
  UikDropdownItem
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";

class DocumentAttachmentPanel extends React.Component {
  constructor(props) {
    super();
    this.resizeTimeout = null;

    this.state = {
      attachmentFiles: [],
      conversation: {},
      documentAttachments: props.attachments ? props.attachments : [],
      userRole: security.getUserRole()
    };
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.onResize);
  };

  sidePanelOptionsCallback = element => {
    this.onResize();
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  onResize = () => {
    //resize the comment panel based on window height and elements
    const header = document.getElementById("usersScreenHeader");
    const attachmentHeader = document.getElementById(
      "sidePanelAttachmentHeader"
    );
    const attachmentPanel = document.getElementById(
      "sidePanelAttachmentOptions"
    );

    // caculate offset to subtract
    let offset = 0;
    if (header) {
      //offset += header.offsetHeight;
    }
    if (attachmentHeader) {
      offset += attachmentHeader.offsetHeight;

      const newHeight = window.innerHeight - offset - 1;

      attachmentPanel.setAttribute("style", `height:${newHeight}px`);
    }
  };

  getFileExtension = (fileName) => {
    return fileName.substring(fileName.lastIndexOf('.')+1, fileName.length);
  };

  actionsDropDown = ({ onClick }) => {
    return (
      <div
        className={styles.documentActionsDropDownContainer}
        onClick={onClick}
      >
        &#9679;&#9679;&#9679;
      </div>
    );
  };

  renderDocumentAttachment = (attachment, idx) => {
    return (
      <div key={attachment.id} className={styles.attachmentContentUploading}>
        <div className={styles.attachmentDocumentInfoContainer}>
          <div className={styles.attachmentLinkContainer}>
            <a
              href={`${apiUrl}/document_attachments/${attachment.id}/file`}
              target="_blank"
              className={styles.attachmentLink}
            > 
              <div className={styles.attachmentDocumentDetails}>
                <div className={styles.attachmentDocumentTitle} title={attachment.title}>{attachment.title}</div>
                <div className={styles.attachmentDocumentFileExtension}>{this.getFileExtension(attachment.filename)}</div> 
              </div>
            </a>
            <UikDropdown
              DisplayComponent={this.actionsDropDown}
              position="bottomRight"
              className="attachmentPanel"
            >
              <UikDropdownItem>
                <a
                  href={`${apiUrl}/document_attachments/${attachment.id}/file`}
                  target="_blank"
                  className={styles.downloadLink}
                > 
                  <span className={styles.downloadText}>Download</span>
                </a>
              </UikDropdownItem>
              {this.state.userRole !== "viewer" && !this.props.documentApproved && <UikDropdownItem 
              onClick={e => this.props.handleDeleteAttachmentModalOpen(e, attachment.id, idx)}
              >
                Delete
              </UikDropdownItem>}
              {this.state.userRole !== "viewer" && !this.props.documentApproved && <UikDropdownItem
                onClick={e => this.props.handleRenameAttachmentModalOpen(e, attachment.title, attachment.id, idx)}
              >
                Rename
              </UikDropdownItem>}
            </UikDropdown>
          </div>
        </div>
        <div className="clear"></div>
      </div>
    );
  };

  onAttachmentDrop = (acceptedFiles) => {
    const token = security.getToken();

    this.setState({
      attachmentFiles: this.state.attachmentFiles.concat(acceptedFiles)
    });

    acceptedFiles.map((attachmentFile, index) => {
      const pctIndex = this.state.attachmentFiles.length + index;

      const postConfig = {
        headers: {
          Authorization: "Bearer " + token
        },
        onUploadProgress: progressEvent => {
          const attachmentFilePercent = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          if (attachmentFilePercent >= 100) {
            this.setState({ ["attachmentFilePercentages" + pctIndex]: 100 });
          } else {
            this.setState({
              ["attachmentFilePercentages" + pctIndex]: attachmentFilePercent
            });
          }
        }
      };

      this.setState({ ["attachmentFilePercentages" + pctIndex]: 0 });

      const attachmentFormData = new FormData();
      attachmentFormData.append("attachment[filename]", attachmentFile.name);
      attachmentFormData.append("attachment[document_attachment_file]", attachmentFile);
      attachmentFormData.append("attachment[document_id]", this.props.docId);
      axios
        .post("/document_attachments.json", attachmentFormData, postConfig)
        .then(response => {
          let docAttachments = this.state.documentAttachments;
          docAttachments.push(response.data);
          this.state.attachmentFiles.map((file, index) => {
            if (file.name.trim().toLowerCase() === response.data.filename.trim().toLowerCase()) {
              let newFileAttachmentsArray = this.state.attachmentFiles
              newFileAttachmentsArray.splice(index, 1);
              this.setState({
                attachments: this.props.sortDocumentAttachments(docAttachments),
                attachmentFiles: newFileAttachmentsArray
              });
            }
          })
        })
        .catch(error => {
          console.log("error", error);
          this.setState({
            ["attachmentFileError" + pctIndex]: "error",
            ["attachmentFilePercentages" + pctIndex]: 100
          });
          this.props.showAttachmentFailNotification();
        });
    });
  };

  renderEmptyStateAttachmentDocumentsUploader = () => {
    return (
      <div className={this.state.documentAttachments.length === 0 && this.state.attachmentFiles.length === 0 ? styles.emptyStateAttachmentContentUploaderBox : styles.hideEmptyStateAttachmentContentUploaderBox}>
        <Dropzone onDrop={this.onAttachmentDrop} multiple={true} disabled={this.state.userRole !== "viewer" && !this.props.documentApproved ? false : true}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={styles.emptyStateDropzone
              }
              >
                <input {...getInputProps()} />
                    <div className={styles.emptyStateUploaderContainer}>
                      <span className={styles.emptyStateUploaderIconContainer}>
                      <img className={styles.emptyStateUploaderIcon} src={this.state.userRole !== "viewer" && !this.props.documentApproved ? uploadIcon : noUploadIcon}></img>
                      </span>
                      <span className={styles.emptyStateUploaderPlaceholder}>
                        {this.state.userRole !== "viewer" && !this.props.documentApproved ? "There are no files attached." : "There are no files attached."}
                      </span>
                      <div className={this.state.userRole !== "viewer" && !this.props.documentApproved ? "attachment-upload-button-empty" : "attachment-upload-button-empty-viewer"}>
                        <Button
                          original
                          text={"Upload"} />
                        <span className={styles.uploadCaption}>
                        or drop file here to upload 
                        </span>
                      </div>
                    </div>
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  };

  renderBottomAttachmentDocumentsUploader = () => {
    return (
      <div className={styles.attachmentContentUploaderBox}>
        <Dropzone onDrop={this.onAttachmentDrop} multiple={true}>
          {({ getRootProps, getInputProps }) => {
            return (
              <div
                {...getRootProps()}
                className={styles.dropzone
              }
              >
                <input {...getInputProps()} />
                <div className={styles.uploaderContainer}>
                  <span className={styles.uploaderIconContainer}>
                  <img className={styles.uploaderIcon} src={uploadIcon}></img>
                  </span>
                  <div className={this.state.userRole !== "viewer" && !this.props.documentApproved ? "attachment-upload-button" : "attachment-upload-button-viewer"}>
                    <Button
                      original
                      text={"Upload Attachment"} />
                  </div>
                </div>
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  };

  renderPopUpAttachmentDocumentsUploader = () => {
    return (
      <Dropzone onDrop={this.onAttachmentDrop} multiple={true}>
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              className={isDragActive ? styles.showPopUpUploader : styles.hidePopUpUploader}
            >
            <div className={isDragActive && styles.dashBorder}>
              {(!isDragActive) && <div>
                {this.state.documentAttachments.map((attachment, idx) => {
                  return this.renderDocumentAttachment(attachment, idx);
                })}
              </div>}
              {!isDragActive && this.state.attachmentFiles &&
              this.state.attachmentFiles.map((att, index) => {
                return this.renderAttachmentDocumentsUploaderProgress(att, index);
              })}
              {isDragActive && <div className={styles.emptyStateUploaderContainer}>
                <span className={styles.emptyStateUploaderIconContainer}>
                <img className={styles.emptyStateUploaderIcon} src={uploadIcon}></img>
                </span>
                <span className={styles.emptyStateUploaderPlaceholder}>
                  Drag and drop your files here
                </span>
              </div>}
            </div>
            </div>
          );
        }}
      </Dropzone>
    );
  };

  closeFailedAttachment = (fileIdx) => {
    let attachmentFileArray = this.state.attachmentFiles;
    attachmentFileArray.splice(fileIdx, 1);
    this.setState({
      attachmentFiles: attachmentFileArray
    });
  };

  renderAttachmentDocumentsUploaderProgress = (att, idx) => {
    const attProgressPercentage = this.state["attachmentFilePercentages" + idx];
    const attError = this.state["attachmentFileError" + idx];
    return (
      <div className={styles.attachmentContentUploading}>
        <div>
          <div className={styles.uploadingFilename}>{att.name}</div>
          {attProgressPercentage === 100 && attError && (
            <div className={styles.errorBox}>
              <img src={Warning} className={styles.warning} />
              <span className={styles.errorMessage}>
                This file did not upload.
              </span>
            </div>
          )}
          {!attError && attProgressPercentage == 100 && (
            <span className={styles.uploaderIconCheck}>
              <Uikon>check</Uikon>
            </span>
          )}
          {attProgressPercentage < 100 && (
            <Line
              className={styles.uploadProgress}
              percent={attProgressPercentage}
              strokeWidth="1"
              strokeColor="#1b8c96"
              strokeLinecap="square"
            />
          )}
        </div>
        {attError && <span
          className={styles.closeAttachmentPanel}
          onClick={() => this.closeFailedAttachment(idx)}
        >
          &times;
        </span>
        }
      </div>
    );
  };

  render() {
    const nonViewerSidePanel = this.state.documentAttachments.length === 0 ? styles.emptySidePanelOptions : styles.sidePanelOptions
    const viewerSidePanel = this.state.documentAttachments.length === 0 ? styles.emptySidePanelOptions : styles.viewerSidePanelOptions
    
    return (
      <div
        id="documentAttachmentPanel"
        className={styles.documentAttachmentPanel}
      >
        <div id="sidePanelAttachmentHeader" className={styles.sidePanelHeader}>
          <div>
            <span className={styles.sidePanelHeadingTitle}>Attachments</span>
            <span
              className={styles.closeAttachmentPanel}
              onClick={() => this.props.close()}
            >
              &times;
            </span>
          </div>
          <span className={styles.sidePanelSubheading}>Files associated with your document</span>
          </div>
        <div
          id="sidePanelAttachmentOptions"
          className={this.state.userRole !== "viewer" && !this.props.documentApproved ? nonViewerSidePanel : viewerSidePanel}
          ref={this.sidePanelOptionsCallback}
        >
          {this.renderPopUpAttachmentDocumentsUploader()}
        </div>
        <div className={this.state.documentAttachments.length === 0 && this.state.attachmentFiles.length === 0 ? styles.emptyAttachmentUploaderContainer : styles.attachmentUploaderContainer}>
          {this.state.documentAttachments.length === 0 && this.renderEmptyStateAttachmentDocumentsUploader()}
          {this.state.documentAttachments.length > 0 && this.renderBottomAttachmentDocumentsUploader()}
          {this.state.attachmentFiles && this.state.documentAttachments.length === 0 &&
              this.state.attachmentFiles.map((att, index) => {
                return <div className={styles.firstUploadProgress}>
                  {this.renderAttachmentDocumentsUploaderProgress(att, index)}
                </div>
          })}
        </div>
      </div>
    );
  }
}

DocumentAttachmentPanel.propTypes = {
  close: PropTypes.func,
  docId: PropTypes.number,
  handleDeleteAttachmentModalOpen: PropTypes.func,
  handleRenameAttachmentModalOpen: PropTypes.func,
  showAttachmentFailNotification: PropTypes.func,
  documentApproved: PropTypes.bool,
  sortDocumentAttachments: PropTypes.func
};

export default DocumentAttachmentPanel;
