/* External libraries */
import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { createForm, formShape } from "rc-form";
import history from "../../history";

import "../../animate.css";

import httpClient from "../../lib/HttpClient";
import security from "../../services/Security";

import notificationService from "../../services/Notifications";

/* UI Kit */
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikWidget,
  UikHeadline,
  UikDivider
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";
import styles from "../Users/UserEditForm.module.css";

/* Variables */

class LibraryEditFormBuild extends React.Component {
  static propTypes = {
    form: formShape
  };

  constructor() {
    super();

    const currentUserRole = security.getUserRole();

    this.state = {
      currentUser: {
        role: currentUserRole
      }
    };
  }

  handleSave(e) {
    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      if (!error) {
        const libraryFormData = {
          library: {
            name: values[`name${this.props.library.id}`]
          }
        };

        if (this.state.role) {
          libraryFormData.user.role = this.state.role;
          console.log(libraryFormData);
        }

        httpClient
          .put(`/libraries/${this.props.library.id}.json`, libraryFormData)
          .then(response => {
            console.log("saved!");

            notificationService.addNotification(
              "Library Saved",
              "Your changes to this library has been saved",
              "success"
            );

            this.props.onUpdate(response.data);
          });
      } else {
        console.log("error", error, values);
      }
    });
  }

  handleDelete(e) {
    e.preventDefault();
    const { onDelete } = this.props;
    if (!window.confirm("Are you sure you want to delete this library?")) {
      return false;
    }
    httpClient
      .delete(`/libraries/${this.props.library.id}.json`)
      .then(response => {
        notificationService.addNotification(
          "Library Deleted",
          "Library has been deleted",
          "danger"
        );
        onDelete(this.props.library);
      })
      .catch(error => {
        notificationService.addNotification(
          "Library Cannot Be Deleted",
          "Libraries containing documents cannot be deleted",
          "danger"
        );
      });
  }

  render() {
    const { library } = this.props;
    const { getFieldError, getFieldDecorator } = this.props.form;

    return (
      <div className={styles.editUserContainer}>
        <UikWidget>
          <div className={styles.editContainer}>
            <UikHeadline>Edit Library</UikHeadline>
          </div>
          <UikDivider />
          <div className={styles.editActionButtons}>
            <UikFormInputGroup>
              {getFieldDecorator(`name${library.id}`, {
                initialValue: library.name,
                rules: [
                  {
                    required: true,
                    message: "Please enter a library name."
                  }
                ]
              })(
                <UikInput
                  label="Library Name"
                  errorMessage={(getFieldError(`name${library.id}`) || []).join(
                    ", "
                  )}
                />
              )}
            </UikFormInputGroup>
          </div>
          <UikDivider />
          <div className={styles.editActionButtons}>
            <UikButton
              className="floatLeft"
              onClick={e => this.handleSave(e)}
              primary
            >
              Save
            </UikButton>
            <UikButton
              className="floatRight"
              onClick={e => this.handleDelete(e)}
              error
            >
              Delete
            </UikButton>
            <div className="clear" />
          </div>
        </UikWidget>
      </div>
    );
  }
}

LibraryEditFormBuild.propTypes = {
  library: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onNotify: PropTypes.func.isRequired
};

const LibraryEditForm = createForm()(LibraryEditFormBuild);
export default LibraryEditForm;
