import React, { useState } from "react";
import { UikInput } from "@uik";
import Button from "../Common/Buttons/ButtonTwoState";
import { apiUrl } from "../../lib/axios-client";
import history from "../../history";
import axios from "axios";
import notificationService from "../../services/Notifications";

import "./Verify2FA.scss";

export default ({ email, password, onSuccess }) => {
    const [verificationCode, setVerificationCode] = useState("");
    const [verifyButtonDisabled, setVerifyButtonDisabled] = useState(false);
    const [error, setError] = useState(false);

    const submitVerificationCode = () => {
        if (verificationCode !== "") {
            setVerifyButtonDisabled(true);
            axios.post(apiUrl + '/login.json',
                {
                    user: {
                        email,
                        password
                    },
                    verification_code: verificationCode
                }).then(response => {
                    setVerifyButtonDisabled(false);
                    const jwt = response.headers.authorization.replace(
                        /Bearer /,
                        ""
                    );
                    onSuccess(jwt);
                }).catch(() => {
                    setError(true);
                    setVerifyButtonDisabled(false);
                })
        } else {
            setError(true);
        }
    }

    const resendVerification = () => {
        axios.post(apiUrl + '/2fa/send',
            {
                user: {
                    email,
                    password
                }
            }
        ).then(() => {
            setError(false);
            notificationService.addNotification(
                "Verification code sent successfully.",
                "New verification code has been successfully sent to the phone number provided.",
                "success"
            );
        })
    }

    return (
        <div className="verify2fa-container">
            <UikInput
                label="Verification code"
                type="text"
                value={verificationCode}
                className={error ? "error" : ""}
                onChange={e => setVerificationCode(e.target.value.replace(/\D/g, ""))}
            />
            {error &&
                <div className="code-invalid">
                    Verification code is invalid
                </div>
            }
            <Button
                activeText="Verify account"
                disabledText="Processing"
                disabled={verifyButtonDisabled}
                onClick={submitVerificationCode}
            />
            <button className="resend-verification" onClick={resendVerification}>
                Resend verification code
            </button>
        </div>
    )
}