import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { createForm, formShape } from 'rc-form';

/* UI Kit */
import  { 
  UikInput
} from '@uik';
import '@uik/styles.css';

import styles from './InputField.module.scss'

/* Variables */
const cookies = new Cookies();

class InputFieldBuild extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  handleInputChange = (e) => {
    const { name, values } = this.props;
    e.preventDefault();

    values[name] = e.target.value;
  }

  render() {
    const { label, name, placeholder, required, defaultValue, values } = this.props;
    const { getFieldError, getFieldDecorator } = this.props.form;

    return(
      <div>
        <UikInput
          className={styles.metadataField}
          label={label}
          defaultValue={defaultValue}
          errorMessage={(getFieldError(`${name}`) || []).join(', ')}
          onChange={(e) => this.handleInputChange(e)}
        />
      </div>
    );
  }
}

InputFieldBuild.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.any,
  values: PropTypes.object
};

const InputField = createForm()(InputFieldBuild);
export default InputField;
