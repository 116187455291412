/* External libraries */
import React from "react";
import Cookies from "universal-cookie";
import Select from "react-select";
import { createForm, formShape } from "rc-form";
import history from "../../history";

import httpClient from "../../lib/HttpClient";
import security from "../../services/Security";

import notificationService from "../../services/Notifications";

/* UI Kit */
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikWidget,
  UikHeadline,
  UikDivider
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";
import styles from "./LibraryCreateForm.module.css";

/* Variables */
const cookies = new Cookies();

class LibraryCreateFormBuild extends React.Component {
  static propTypes = {
    form: formShape
  };

  constructor() {
    super();

    const currentUserRole = security.getUserRole();

    this.state = {
      invalidUserInviteForm: false,
      currentUser: {
        role: currentUserRole
      }
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      if (!error) {

        const libraryFormData = {
          library: {
            name: values.name
          }
        };

        httpClient
          .post("/libraries.json", libraryFormData)
          .then(response => {
            console.log("Created!", response);
            notificationService.addNotification(
              "Library Created",
              "The library has been created",
              "success"
            );

            history.push("/libraries");
            console.log("create response", response.data);
            this.props.onCreate(response.data);
          })
          .catch(err => {
            const message = err.name ? "The library name has already been taken" : "An erorr occurred while creating your library";

            notificationService.addNotification(
              "An Error Occurred",
              message,
              "danger"
            );

            console.log("error", err);
          });
      } else {
        console.log("error", error, values);
      }
    });
  }

  handleCancel = e => {
    history.push("/libraries");
  };

  render() {
    const { getFieldError, getFieldDecorator } = this.props.form;

    return (
      <div className={styles.libraryCreateContainer}>
        <UikWidget>
          <div className={styles.createContainer}>
            <UikHeadline>Create Library</UikHeadline>
          </div>
          <UikDivider />
          <div className={styles.createActionButtons}>
            <UikFormInputGroup>
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: "Please enter a library name."
                  }
                ]
              })(
                <UikInput
                  label="LIBRARY NAME"
                  errorMessage={(getFieldError("name") || []).join(", ")}
                />
              )}
            </UikFormInputGroup>
          </div>
          <UikDivider />
          <div className={styles.createActionButtons}>
            <UikButton
              className="floatLeft"
              onClick={e => this.handleSubmit(e)}
              primary
            >
              Save
            </UikButton>
            <UikButton
              className="floatRight"
              onClick={e => this.handleCancel(e)}
            >
              Cancel
            </UikButton>
            <div className="clear" />
          </div>
        </UikWidget>
      </div>
    );
  }
}

const LirbaryCreateForm = createForm()(LibraryCreateFormBuild);
export default LirbaryCreateForm;
