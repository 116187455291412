/* External libraries */
import React from "react";
import Select from "react-select";
import "./AudienceSelect.scss";

/* Variables */

class AudienceSelect extends React.Component {
  constructor(props) {
    super(props);

    let selectedOption = this.props.options.find(option => option.value === this.props.defaultValue);

    this.state = {
      selected: selectedOption
    }
  }

  onChange = selectedAudience => {
    const { name, values } = this.props;

    values[name] = selectedAudience.value;
    this.setState({
      selected: selectedAudience
    });
  };

  render() {
    const { name, options } = this.props;

    return (
      <div className={"audience_select"}>
        <span className="uik-content-title__wrapper">{name}</span>
        <Select
          placeholder="Select an Audience"
          value={this.state.selected}
          options={options}
          onChange={e => this.onChange(e)}
          styles={{ control: styles => ({ ...styles, borderColor: '#D6DCE8', fontSize: '14px' }) }}
        />
      </div>
    );
  }
}

export default AudienceSelect;
