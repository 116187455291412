import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { createForm, formShape } from 'rc-form';
import DatePicker from "react-datepicker";

import styles from './DateField.module.scss'

/* Variables */
const cookies = new Cookies();

class DateField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.defaultValue
    };
  }

  componentWillReceiveProps = (nextProps) => {
    const { defaultValue } = nextProps;

    if (nextProps.values[nextProps.name] != this.state.value) {
      this.setState({ value: defaultValue });
    }
  }

  handleInputChange = (date) => {
    const { name, values } = this.props;

    values[name] = date ? date.toISOString() : null;

    this.setState({ value: values[name] });
  }

  render() {
    const { label, name, placeholder, required, defaultValue, values } = this.props;

    let defaultDate;

    if (this.state.value) {
      defaultDate = new Date(this.state.value);
    }

    return (
      <div>
        <span className="uik-content-title__wrapper" style={{ 'display': 'block' }}>{`${label}`}</span>
        <div className="customDatePickerWidth">
          <DatePicker
            className={`uik-input__input ${styles.metadataField}`}
            style={{ 'width': '100%' }}
            selected={defaultDate}
            isClearable
            onChange={this.handleInputChange}
          />
        </div>
      </div>
    );
  }
}

DateField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.any,
  values: PropTypes.object
};

export default DateField;
