import React from "react";
import PropTypes from "prop-types";
import styles from "./CommentPanel.module.scss";
import httpClient from "../../lib/HttpClient";
import moment from "moment";
import Checkmark from "../Common/Checkmark";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin, { defaultSuggestionsFilter } from 'draft-js-mention-plugin';
import './mentionsStyles.css';

/* Papercurve Components */
import Comment from "./Comment";

import commentService from "../../services/Comments";
import security from "../../services/Security";
import notificationService from "../../services/Notifications";

/* UI Kit */
import {
  Uikon,
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikDivider,
  UikDropdown,
  UikDropdownItem
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";
import 'draft-js/dist/Draft.css';

import sendIcon from "../../images/icons/16px/send.svg";
import moreSVG from "../../images/icons/svg/more.svg";
import CloseSVG from "../../images/icons/svg/close.svg";
import leftarrowSVG from "../../images/icons/svg/left-arrow.svg";
import sendButtonSVG from "../../images/icons/svg/send-comment-button.svg";
import spinnerSVG from "../../images/icons/svg/loading.svg";

/* Variables */

class CommentPanel extends React.Component {
  constructor(props) {
    super(props);

    this.resizeTimeout = null;
    this.myRef = React.createRef();
    this.commentPanelRef = React.createRef();
    this.focusedSuggestionRef = React.createRef();

    let mentions = []

    this.props.approvers.forEach(approver => {
      if (approver.active) {
        mentions.push(
          {
            id: approver.id,
            name: `${approver.first_name} ${approver.last_name}`,
            title: `${approver.title}`
          }
        )
      }
    })

    this.state = {
      conversation: {},
      commentBody: "",
      currentUserId: security.getUserId(),
      addTimestamp: false,
      currentTimestamp: 0,
      editorState: EditorState.createEmpty(),
      mentions,
      suggestions: mentions,
      sendButtonDisabled: false,
      showSpinner: false
    };

    this.mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      theme: {
        mention: 'mention',
        mentionSuggestions: 'mentionSuggestions',
        mentionSuggestionsEntry: 'mentionSuggestionsEntry',
        mentionSuggestionsEntryText: 'mentionSuggestionsEntryText',
        mentionSuggestionsEntryFocused: 'mentionSuggestionsEntryFocused',
        mentionSuggestionsEntryAvatar: 'mentionSuggestionsEntryAvatar',
      }
    });
  }

  componentWillMount = () => {
    if (this.props.legacyDocument) {
      this.legacyRetrieveConversation();
    } else {
      this.currentRetrieveConversation();
    }
  };

  componentDidMount = () => {
    document.addEventListener("reload_comments", e => {
      if (this.props.legacyDocument && e.detail.docId == this.props.docId) {
        this.legacyRetrieveConversation();
      } else {
        this.retrieveConversation(this.state.conversation.id);
      }
    });

    if (this.props.is_video) {
      document.addEventListener("video_timestamp_update", e => {
        this.setState({ currentTimestamp: e.detail.timestamp });
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.conversation && this.state.conversation.id && prevState.conversation && !prevState.conversation.id && this.myRef.current) {
      this.myRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (this.props.currentXfdfHighlightId === null && prevProps.currentXfdfHighlightId !== null) {
      this.setState({ conversation: {} });
    }
    if (this.props.currentXfdfHighlightId !== prevProps.currentXfdfHighlightId && this.props.currentXfdfHighlightId !== null) {
      httpClient
        .get(`/conversations/xfdf/${this.props.currentXfdfHighlightId}.json`)
        .then(response => {
          this.setState({ conversation: response.data });
        });
    }

    if (this.commentPanelRef.current) {
      this.commentPanelRef.current.scrollTop = this.commentPanelRef.current.scrollHeight;
    }
  }

  retrieveConversation = (conversationId) => {
    if (!conversationId) {
      conversationId = this.state.conversation.id;
    }

    httpClient
      .get(`/conversations/${conversationId}.json`)
      .then(response => {
        this.setState({ conversation: response.data });
      });
  };

  legacyRetrieveConversation = () => {
    const { conversationBlockId } = this.props;

    // Get conversation using block identifier
    httpClient
      .get(`/conversations/legacy/${conversationBlockId}.json`)
      .then(response => {
        this.setState({ conversation: response.data });
      });
  };

  currentRetrieveConversation = () => {
    const { currentXfdfHighlightId } = this.props;

    // Get conversation using block identifier
    httpClient
      .get(`/conversations/xfdf/${currentXfdfHighlightId}.json`)
      .then(response => {
        this.setState({ conversation: response.data });
      });
  };

  addComment = () => {
    if (this.props.legacyDocument) {
      this.legacyAddComment();
    } else {
      this.currentAddComment();
    }
  };

  toggleConversationStatus = () => {
    let status = "resolved";
    if (this.state.conversation.status === "resolved") {
        status = "unresolved";
    }

    httpClient.put(`/conversations/${this.state.conversation.xfdf_highlight_id || this.state.conversation.block_identifier}.json`,{
        conversation: {
            status
        }
    }).then(res => {
        this.setState({conversation: res.data});
        this.props.reloadConversations();
    })
}

  currentAddComment = () => {
    let that = this;
    if (this.state.editorState.getCurrentContent().getPlainText() === "") {
      return;
    }

    const { docId, pageNumber } = this.props;

    const contentState = convertToRaw(this.state.editorState.getCurrentContent());

    let mentions = Object.values(contentState.entityMap).filter(v => v.type === "mention").map(v => v.data.mention);

    mentions = mentions.filter((mention, index, mentions) => mentions.findIndex(i => i.id === mention.id) === index)


    const commentData = {
      conversation: {
        document_id: parseInt(docId),
        page_number: parseInt(pageNumber),
        xfdf_highlight_id: this.props.currentXfdfHighlightId,
        xfdf_highlight_string: this.props.currentXfdfHighlightString
      },
      comment: {
        body: this.state.editorState.getCurrentContent().getPlainText()
      }
    };

    if (mentions.length > 0) {
      commentData.mentions = mentions;
    }

    if (this.props.is_video && this.state.addTimestamp) {
      commentData.comment.video_timestamp = this.state.currentTimestamp;
    }

    this.setState({sendButtonDisabled: true});

    setTimeout(()=> {
      if (that.state.sendButtonDisabled){
        that.setState({
          showSpinner: true
        })
      }
    },400);
    httpClient.post("/comments.json", commentData).then(response => {
      let conversation = this.state.conversation;

      if (!(this.state.conversation.id > 0)) {
        this.retrieveConversation(response.data.conversation.id);
      } else {
        conversation.comments.push(response.data);
      }

      this.props.reloadConversations();

      const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));

      this.setState(
        {
          conversation: conversation,
          editorState,
          sendButtonDisabled: false,
          showSpinner: false
        },
        () => {
          const commentPanel = document.getElementById("sidePanelOptions");
          commentPanel.scrollTop = commentPanel.scrollHeight;
        }
      );

      commentService.reloadComments(parseInt(this.props.docId));
    });
  };

  legacyAddComment = () => {
    let that = this;
    if (this.state.editorState.getCurrentContent().getPlainText() === "") {
      return;
    }

    const { conversationBlockId, docId, pageNumber } = this.props;

    const contentState = convertToRaw(this.state.editorState.getCurrentContent());
    const mentions = Object.values(contentState.entityMap).filter(v => v.type === "mention").map(v => v.data.mention);

    const commentData = {
      conversation: {
        document_id: parseInt(docId),
        page_number: parseInt(pageNumber),
        block_identifier: conversationBlockId
      },
      comment: {
        body: this.state.editorState.getCurrentContent().getPlainText()
      }
    };

    if (mentions.length > 0) {
      commentData.mentions = mentions;
    }

    if (this.props.is_video && this.state.addTimestamp) {
      commentData.comment.video_timestamp = this.state.currentTimestamp;
    }

    this.setState({sendButtonDisabled: true});

    setTimeout(()=> {
      if (that.state.sendButtonDisabled){
        that.setState({
          showSpinner: true
        })
      }
    },400);

    httpClient.post("/comments.json", commentData).then(response => {
      let conversation = this.state.conversation;

      if (!(this.state.conversation.id > 0)) {
        this.legacyRetrieveConversation();
      } else {
        conversation.comments.push(response.data);
      }

      this.props.reloadConversations();
      const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));

      this.setState(
        {
          conversation: conversation,
          editorState,
          sendButtonDisabled: false,
          showSpinner: false
        },
        () => {
          const commentPanel = document.getElementById("sidePanelOptions");
          commentPanel.scrollTop = commentPanel.scrollHeight;
        }
      );

      commentService.reloadComments(parseInt(this.props.docId));
    });
  };

  renderComments = () => {
    let commentFound = false;
    return this.state.conversation.comments.map((i, j) => {
      let commentedAtTime = i.commented_at;
      const twelveHours = 60 * 60 * 1000 * 12;
      let canDelete = !this.props.approved && (security.getUserRole() == "admin" || (i.user.id == security.getUserId() && (Date.now() - new Date(commentedAtTime)) < twelveHours));
      if (this.props.selectedCommentId && this.props.selectedCommentId === i.id) {
        commentFound = true;
        return (
          <div key={i.id} ref={this.myRef} onClick={() => this.props.updateSelectedCommentId(i.id)} className={styles.comment}>
            <Comment
              comment={i}
              currentUserId={this.state.currentUserId}
              onDeleteComment={this.onDeleteComment}
              canDelete={canDelete}
            />
          </div>
        );
      } else {
        if (this.props.currentXfdfHighlightId && this.props.selectedCommentId && j === this.state.conversation.comments.length - 1 && !commentFound) {
          notificationService.addNotification(
            "Comment removed.",
            "Comment no longer exists.",
            "danger"
          );
        }
        return (
          <div key={i.id} onClick={() => this.props.updateSelectedCommentId(i.id)} className={styles.comment}>
            <Comment
              comment={i}
              currentUserId={this.state.currentUserId}
              onDeleteComment={this.onDeleteComment}
              canDelete={canDelete}
            />
          </div>
        );
      }
    });
  };

  onDeleteComment = id => {
    if (window.confirm("Are you sure you want to delete this comment?")) {
      httpClient.delete(`/comments/${id}.json`).then(response => {
        this.props.updateSelectedCommentId(null);
        notificationService.addNotification(
          "Comment removed.",
          "Comment has been deleted.",
          "danger"
        );
        if (response.data.comment_count > 0) {
          commentService.reloadComments(parseInt(this.props.docId));
        } else {
          const webViewerEl = document.getElementById('webViewer');
          const annotManager = window.WebViewer.getInstance(webViewerEl).docViewer.getAnnotationManager();
          const annot = annotManager.getAnnotationById(this.props.currentXfdfHighlightId);

          annotManager.deleteAnnotation(annot);
          this.props.closeConversation();
        }
        this.props.reloadConversations();
      });
    }
  };

  onDeleteConversation = () => {
    const { conversationBlockId, currentXfdfHighlightId } = this.props;

    let conversationIdentifier = currentXfdfHighlightId;

    if (conversationBlockId) {
      conversationIdentifier = conversationBlockId;
    }

    if (window.confirm("Are you sure you want to delete this Conversation?")) {
      httpClient
        .delete(`/conversations/${conversationIdentifier}.json`)
        .then(response => {
          if (currentXfdfHighlightId === conversationIdentifier) {
            const webViewerEl = document.getElementById('webViewer');
            const annotManager = window.WebViewer.getInstance(webViewerEl).docViewer.getAnnotationManager();
            const annot = annotManager.getAnnotationById(conversationIdentifier);

            annotManager.deleteAnnotation(annot);
          }

          this.props.reloadConversations();
          this.props.closeConversation();
          notificationService.addNotification(
            "Conversation removed.",
            "Conversation has been deleted.",
            "danger"
          );
        });
    }
  };

  addTimestamp = e => {
    this.setState({ addTimestamp: e.target.checked });
  };

  onChange = editorState => {
    this.setState({ editorState });
  }

  onSearchChange = ({ value }) => {
    this.setState({
      suggestions: defaultSuggestionsFilter(value, this.state.mentions),
    });
  };

  entryComponent = (props) => {
    const {
      mention,
      theme,
      searchValue,
      isFocused,
      ...parentProps
    } = props;

    let name = mention.name.split(" ");
    return (
      <div {...parentProps}>
        <div className={theme.mentionSuggestionsEntryAvatar}>
          {`${name[0].charAt(0)}${name[1].charAt(0)}`}
        </div>
        <div className="mentionSuggestionsTextBox">
          <div className={theme.mentionSuggestionsEntryText}>
            {mention.name}
          </div>

          <div className="mentionSuggestionsTitle">
            {mention.title}
          </div>
        </div>
      </div>
    )
  };

  mentionsSuggestionsComponent = () => {
    return (
      <div className="hello"
        onSearchChange={this.onSearchChange}
        suggestions={this.state.suggestions}
        entryComponent={this.entryComponent}
      >
      </div>
    )
  }

  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.mentionPlugin];

    const actionsDropDown = ({ onClick }) => {
      return (
        <span onClick={onClick}>
          <img className={styles.moreIcon} src={moreSVG} />
        </span>
      );
    };

    return (
      <div id="conversationPanel" className={styles.commentPanel} ref={this.commentPanelRef}>
        <div className={styles.sidePanelHeader} id="sidepanelheader">
          {!this.props.is_video &&
          <span className={styles.backArrow} onClick={() => this.props.closeConversation()}>
            <img src={leftarrowSVG} />
          </span>}
          <span className={styles.sidePanelHeadingTitle}>Comments</span>
          <span
            className={styles.closeComment}
            onClick={() => this.props.closeConversation(true)}
          >
            <img src={CloseSVG}/>
          </span>
          {(!this.props.approved &&
            this.state.conversation &&
            !this.state.conversation.block_identifier &&
            this.state.conversation.comments &&
            (
              security.getUserRole() == "admin" ||
              (this.state.conversation && this.state.conversation.comments[0].conversation.started_by_user_id == security.getUserId())
            )
          ) && (
              <div className={styles.conversationActionsDropDownContainer}>
                <UikDropdown
                  DisplayComponent={actionsDropDown}
                  position="bottomRight"
                >
                  <UikDropdownItem onClick={e => this.onDeleteConversation(e)}>
                    Delete
                </UikDropdownItem>
                </UikDropdown>
              </div>
            )}
          {(!this.props.is_video && this.state.conversation && this.state.conversation.comments && this.state.conversation.comments.length > 0) &&
            <div className={styles.checkmark}>
              <Checkmark disabled={this.props.readOnly} status={this.state.conversation.status} onClick={() => {this.toggleConversationStatus()}}/>
            </div>
          }
        </div>
        <div
          className={styles.sidePanelOptions}
          id="sidePanelOptions"
          ref={this.sidePanelOptionsCallback}
        >
          <div>{this.state.conversation && this.state.conversation.id > 0 && this.renderComments()}</div>
        </div>
        {!this.props.readOnly && (
          <div className={styles.messageInput} id="messageInput">
            {this.props.is_video && (
              <div class={styles.addTimestamp}>
                <input type="checkbox" onChange={this.addTimestamp} />
                Add Timestamp (
                {moment()
                  .startOf("day")
                  .seconds(this.state.currentTimestamp)
                  .format("H:mm:ss")}
                )
              </div>
            )}
            <div className={styles.editor}>
              <Editor
                editorState={this.state.editorState}
                onChange={this.onChange}
                placeholder="Type a message..."
                plugins={plugins}
              />
              <MentionSuggestions
                onSearchChange={this.onSearchChange}
                suggestions={this.state.suggestions}
                entryComponent={this.entryComponent}
                onAddMention={this.addMention}
              />
            </div>

            <div className={styles.messageInputSubmit}>
              <button
                disabled={this.state.sendButtonDisabled}
                onClick={this.addComment}
                className={styles.sendButton}
              >
                  <img className={`${this.state.showSpinner ? styles.hideSendButton : ``}`} src={sendButtonSVG}/>
                  <img className={styles.loading} src={spinnerSVG}/>
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

CommentPanel.propTypes = {
  closeConversation: PropTypes.func,
  conversationBlockId: PropTypes.string,
  docId: PropTypes.number,
  pageNumber: PropTypes.number,
  legacyDocument: PropTypes.bool,
  currentXfdfHighlightId: PropTypes.string,
  currentXfdfHighlightString: PropTypes.string
};

export default CommentPanel;
