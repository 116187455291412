import React from "react";
import PropTypes from "prop-types";
import styles from "./ApproverListItem.module.scss";

/* UI Kit */
import { UikCheckbox, Uikon } from "@uik";
import "@uik/styles.css";
import "../../font.scss";

class ApproverAddedItem extends React.Component {
  render() {
    const { suggestion } = this.props;

    return (
      <div
        className={styles.approverListItem + " " + styles.stylesApproverAddItem}
      >
        <div className={styles.approverListItemInner}>
          <div className={styles.approverListItemAvatarContainer}>
            <div className={styles.approverListItemAvatar}>
              <span className={styles.approverListItemInitials}>
                {this.props.added.suggestion.first_name.charAt(0)}
                {this.props.added.suggestion.last_name.charAt(0)}
              </span>
            </div>
          </div>
          <div className={styles.approverListItemNameplate}>
            <span className={styles.approverListItemNameplateName}>
              {this.props.added.suggestion.first_name}{" "}
              {this.props.added.suggestion.last_name}
            </span>
            <br />
            <span className={styles.approverListItemNameplateTitle}>
              {this.props.added.suggestion.title}
              &nbsp;
            </span>
            &nbsp; &nbsp;
          </div>
          <div className={styles.actionDelete}>
            <span
              className={styles.deleteApprover}
              onClick={v => this.props.onDeleteAdded(this.props.added.value)}
            >
              &times;
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default ApproverAddedItem;
