import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styles from "./TagListItem.module.css";

const TagListItem = ({ tag, selectedTagId }) => (
  <Link to={`/tags/${tag.id}`}>
    <div className={selectedTagId == tag.id ? styles.selectedTag : styles.tagListItem}>
      <div className={styles.tagListItemNameplate}>
        <span className={styles.tagListItemNameplateName}>{tag.name}</span>
      </div>
    </div>
  </Link>
);

TagListItem.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }).isRequired
};

export default TagListItem;
