import React from "react";
import PropTypes from "prop-types";
import styles from "./SelectedTextPanel.module.scss";

/* UI Kit */
import {
  Uikon,
  UikButton,
  UikDivider
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";

class SelectedTextPanel extends React.Component {
  constructor() {
    super();

    this.state = {
    };
  }

  render() {
    return (
      <div className={styles.selectedTextPanel}>
        <div className={styles.sidePanelHeader}>
          <span>Create new</span>
          <span
            className={styles.closeComment}
            onClick={() => this.props.close()}>
            &times;
          </span>
        </div>
        <div className={styles.sidePanelOptions}>
          <div>
            <UikButton
              primary
              className={styles.button}
              onClick={() => this.props.selectConversation()}
            >
              Comment
            </UikButton>
          </div>
          <div>
            <UikButton
              primary
              className={styles.button}
              onClick={() => this.props.selectReference()}
            >
              Reference
            </UikButton>
          </div>
        </div>
      </div>
    );
  }
}

SelectedTextPanel.propTypes = {
  close: PropTypes.func,
  selectConversation: PropTypes.func,
  selectReference: PropTypes.func,
  selectedBlockId: PropTypes.string,
  docId: PropTypes.number,
  pageNumber: PropTypes.string
}

export default SelectedTextPanel;
