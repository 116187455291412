import React from "react";

import ReactNotification from "react-notifications-component";
import styles from "./Notification.module.scss";
import Warning from "../../images/icons/svg/warning-white.svg";

class Notification extends React.Component {
  constructor() {
    super();
    this.notificationDOMRef = React.createRef();
  }

  componentDidMount = () => {
    document.addEventListener("notification_add", e => {
      this.addNotification(e.detail.title, e.detail.message, e.detail.type);
    });
  };

  addNotification = (title, message, type) => {
    let typeStyle = `type--${type}`;
    const getIcon = () => {
      if (type === "warning") {
        return <img src={Warning} />
      } else {
        return (
          <div className={styles.notificationCustomIcon}>
            <i className="uikon">check</i>
          </div>
        )
      }
    }
    this.notificationDOMRef.current.addNotification({
      title: title,
      message: message,
      content: (
        <div
          className={`${styles.notificationCustomSuccess} ${styles[typeStyle]}`}
        >
          {getIcon()}
          <div className={styles.notificationCustomContent}>{message}</div>
        </div>
      ),
      type: type,
      container: "top-right",
      animationIn: ["animated", "flipInX"],
      animationOut: ["animated", "flipOutX"],
      dismiss: { duration: 5000 },
      dismissable: { click: true }
    });
  };

  render() {
    return <ReactNotification ref={this.notificationDOMRef} />;
  }
}

export default Notification;
