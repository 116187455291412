/* External libraries */
import React from "react";
import ReactNotification from "react-notifications-component";
import PropTypes from "prop-types";
import Select from "react-select";
import { createForm, formShape } from "rc-form";
import history from "../../history";

import security from "../../services/Security";

import httpClient from "../../lib/HttpClient";
import "../../animate.css";

import notificationService from "../../services/Notifications";

/* UI Kit */
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikWidget,
  UikHeadline,
  UikDivider,
  UikCheckbox
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";
import styles from "./UserEditForm.module.css";

/* Variables */

class UserReactivateForm extends React.Component {
  static propTypes = {
    form: formShape
  };

  constructor() {
    super();

    const currentUserRole = security.getUserRole();

    this.state = {
      currentUser: {
        role: currentUserRole
      },
      libraries: [],
      selectedLibraries: []
    };
  }

  componentWillMount = () => {
    // Only Authors and Admins can see users screen
    if (
      this.state.currentUser.role !== "author" &&
      this.state.currentUser.role !== "admin"
    ) {
      history.push("/drafts");
    }
  };

  handleReactivate(e) {
    const { onReactivate } = this.props;
    e.preventDefault();

    httpClient
      .post(`/users/${this.props.user.id}/reactivate`)
      .then(response => {
        console.log("saved!");
        notificationService.addNotification(
          "User Reactivated",
          "This user has been reactivated",
          "success"
        );
        onReactivate();
      });
  }

  render() {
    const { user } = this.props;

    return (
      <div className={styles.editUserContainer}>
        <UikWidget>
          <div className={styles.editContainer}>
            <UikHeadline>Deactivated User</UikHeadline>
            <div className={styles.UserEditFormSubtitle}>
              This user does not have access to Papercurve
            </div>
          </div>
          <UikDivider />
          <div className={styles.editActionButtons}>
            <UikFormInputGroup>
              <UikInput
                disabled
                label="FIRST NAME"
                placeholder="Mary"
                value={user.first_name}
              />

              <UikInput
                disabled
                label="LAST NAME"
                placeholder="Smith"
                value={user.last_name}
              />

              <UikInput
                disabled
                label="EMAIL ADDRESS"
                placeholder="you@company.com"
                value={user.email}
              />

              <UikInput
                disabled
                label="TITLE"
                placeholder="Medical Writer"
                value={user.title}
              />
            </UikFormInputGroup>
          </div>
          <UikDivider />
          <div className={styles.editActionButtons}>
            <UikButton
              className="floatLeft"
              onClick={e => this.handleReactivate(e)}
              primary
            >
              Reactivate
            </UikButton>

            <div className="clear" />
          </div>
        </UikWidget>
      </div>
    );
  }
}

UserReactivateForm.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    username: PropTypes.string,
    email: PropTypes.string
  }).isRequired
};

export default UserReactivateForm;
