import axios from 'axios';

export const apiUrl =
  process.env.NODE_ENV === 'production'
    ? `${process.env.REACT_APP_API_URL}`
    : 'http://localhost:3000';

const axiosClient = axios.create({ baseURL: apiUrl })

export default axiosClient;
