import React, { useState } from "react";
import Checkmark from "../../../images/icons/svg/checkmark-white.svg";

import "./Checkbox.scss";

export const Checkbox = ({ label, name, checked = false, onChange }) => {

    const [boxChecked, setBoxChecked] = useState(checked);

    const checkboxClicked = e => {
        setBoxChecked(e.target.checked);
        onChange(e);
    }

    return (
        <div className="checkbox-container">
            <input
                type="checkbox"
                name={name}
                checked={boxChecked}
                onChange={checkboxClicked}
                className={`checkbox${boxChecked ? ` checked` : ``}`}
            />
            <label className="label" htmlFor={name}>{label}</label>
            <img className={`checkmark${!boxChecked ? ` hidden` : ``}`} src={Checkmark} />
        </div>
    )
}