import React, { useState } from "react";
import { Container, Row } from "react-grid-system";
import Header from "../shared/Header/Header";
import SettingsLeftMenu from "../shared/SettingsLeftMenu/SettingsLeftMenu";
import SecurityMenu from "./SecurityMenu";
import styles from "./SetupSecurity.module.scss";
import ChangePasswordForm from "./ChangePasswordForm";

export const SetupSecurity = () => {
  const [ShowSecurityMenu, setShowSecurityMenu] = useState(true);
  const [ShowChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const renderSecurityMenu = () => {
    setShowSecurityMenu(true)
    setShowChangePasswordForm(false)
  };
  const hideSecurityMenu = () => {
    setShowSecurityMenu(false)
  };
  const renderChangePasswordForm = () => {
    setShowChangePasswordForm(true)
  };

  return (
    <Container fluid className={styles.container}>
      <Header />
      <Row className={styles.row}>
        <SettingsLeftMenu />
        {ShowChangePasswordForm && 
        <ChangePasswordForm
        renderSecurityMenu={() => {renderSecurityMenu()}}/>}
        {ShowSecurityMenu && 
        <SecurityMenu
        hideSecurityMenu={() => hideSecurityMenu()}
        renderChangePasswordForm={() => renderChangePasswordForm()}
        />}
      </Row>
    </Container>
  );
};
