import React from "react";
import { Container } from "react-grid-system";
import { Link } from "react-router-dom";
import httpClient from "../../lib/HttpClient";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";

/* Assets */
import styles from "./Documents.module.scss";
import leftIcon1x from "../../images/icons/16px/left-chevron-large.png";
import leftIcon2x from "../../images/icons/16px/left-chevron-large@2x.png";
import leftIcon3x from "../../images/icons/16px/left-chevron-large@3x.png";

import checkMark from "../../images/icons/svg/Check_thick.svg";
import sortIcon from "../../images/icons/svg/icon_20px_sort.svg";

import "react-datepicker/dist/react-datepicker.css";

import security from "../../services/Security";

/* UI Kit */
import {
  UikInput,
  UikButton,
  Uikon,
  UikButtonGroup,
  UikDropdown,
  UikDropdownItem
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";

/* Papercurve Components */
import Header from "../shared/Header/Header";
import DocumentThumbnail from "./DocumentThumbnail";

import DocumentFilterItem from "./DocumentFilterItem";

/* Variables */

class Documents extends React.Component {
  constructor() {
    super();
    this.state = {
      documents: [],
      searchField: false,
      library: { name: "" },
      documentFields: [],
      selectOptions: [],
      loaded: false,

      filters: this.getLocalStorageItem("filters") || {},
      filterTypes: this.getLocalStorageItem("filterTypes") || {},
      search: "",
      dateTypeFilter: this.getLocalStorageItem("dateTypeFilter") || "approved",
      startDate: this.getLocalStorageItem("startDate") || "",
      endDate: this.getLocalStorageItem("endDate") || "",
      page: this.getLocalStorageItem("page") || 1,
      total_pages: this.getLocalStorageItem("total_pages") || 1,
      sort: this.getLocalStorageItem("sort") || "alpha_asc",
      menuOpen: false,
      tags: []
    };
  }

  getLocalStorageItem = key => {
    let searchOptions = JSON.parse(localStorage.getItem("searchOptions")) || {};
    if (searchOptions[security.getUserId()]) {
      return searchOptions[security.getUserId()][key] || false;
    } else {
      return false;
    }
  };

  setLocalStorageItem = (key, value) => {
    let searchOptions = JSON.parse(localStorage.getItem("searchOptions")) || {};
    if (!searchOptions[security.getUserId()]) {
      searchOptions[security.getUserId()] = {};
    }
    searchOptions[security.getUserId()][key] = value;

    localStorage.setItem("searchOptions", JSON.stringify(searchOptions));
  };

  storeAllSearchOptions = () => {
    this.setLocalStorageItem("filters", this.state.filters);
    this.setLocalStorageItem("filterTypes", this.state.filterTypes);
    this.setLocalStorageItem("search", this.state.search);
    this.setLocalStorageItem("dateTypeFilter", this.state.dateTypeFilter);
    this.setLocalStorageItem("startDate", this.state.startDate);
    this.setLocalStorageItem("endDate", this.state.endDate);
    this.setLocalStorageItem("page", this.state.page);
    this.setLocalStorageItem("total_pages", this.state.total_pages);
    this.setLocalStorageItem("sort", this.state.sort);
    this.setLocalStorageItem("searchField", this.state.searchField);
  };

  componentWillMount = () => {
    const { id } = this.props.match.params;

    this.loadDocumentFields();
    this.loadDocuments(id);
    this.loadLibraries(id);
    this.loadTags();

    if (!this.state.search === "") {
      this.setState({ searchField: true });
    } else {
      this.setState({ searchField: false });
    }

    document.addEventListener("scroll", this.trackScrolling);
  };

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById("documents_container");
    if (this.isBottom(wrappedElement)) {
      if (this.state.page < this.state.total_pages) {
        this.setState({ page: ++this.state.page }, () => {
          const { id } = this.props.match.params;
          this.loadDocuments(id);
        });
      }
    }
  };

  loadLibraries = id => {
    httpClient.get(`/libraries/${id}.json`).then(response => {
      this.setState({
        library: response.data
      });
    });
  };

  loadDocuments = id => {
    const data = {
      page: this.state.page,
      filters: this.state.filters,
      filterTypes: this.state.filterTypes,
      date_type: this.state.dateTypeFilter,
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      search: this.state.search,
      sort: this.state.sort
    };
    httpClient.post(`/libraries/${id}/documents.json`, data).then(response => {
      let documents = this.state.documents;
      if (this.state.page > 1) {
        documents.push(...response.data.documents);
      } else {
        documents = response.data.documents;
      }

      this.setState(
        {
          documents: documents,
          total_pages: response.data.total_pages
        },
        () => {
          //this.loadDocumentMetaData();
        }
      );
    });
  };

  loadDocumentFields = () => {
    httpClient.get("/document_fields.json").then(response => {
      let documentFields = response.data;
      let selectOptions = {};

      documentFields.map(df => {
        if (df.field_type === "select_options") {
          selectOptions[df.key] = df.document_field_select_options;
        }
      });

      documentFields = documentFields.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      this.setState({
        documentFields: documentFields,
        selectOptions: selectOptions
      });
    });
  };

  loadDocumentMetaData = () => {
    const promises = [];
    const documents = this.state.documents;
    for (let i in documents) {
      const promise = httpClient
        .get(`/documents/${documents[i]["id"]}/metadata.json`)
        .then(response => {
          let documentFieldValues = {};

          response.data.map((metadata, idx) => {
            documentFieldValues[metadata.document_field.key] = metadata.value;
          });
          documents[i]["metadata"] = response.data;
          documents[i]["fieldValues"] = documentFieldValues;
        });
      promises.push(promise);
    }
    Promise.all(promises).then(values => {
      this.setState({ documents: documents, loaded: true });
    });
  };

  loadTags = () => {
    httpClient.get("/tags.json").then(response => {
      const tags = [];
      response.data.map((option, index) => {
        tags.push({ value: option.id, label: option.name });
      });
      this.setState({ tags: tags });
    });
  };

  handleDocumentSearch = e => {
    e.preventDefault();

    this.setState({ search: e.target.value }, () => {
      this.filterDocuments();
    });
  };

  handleOpenSearch = () => {
    this.setState({ searchField: true });
  };

  handleCloseSearch = () => {
    this.setState({ searchField: false, search: "" }, () => {
      this.filterDocuments();
    });
  };

  filterDocuments = () => {
    const { id } = this.props.match.params;
    this.setState({ page: 1 }, () => {
      this.storeAllSearchOptions();
      this.loadDocuments(id);
    });
  };

  clearFilter = key => {
    let filters = this.state.filters;
    filters[key] = "";

    this.setState({ filters: filters });
  };

  onFilterChange = (value, key) => {
    let filters = this.state.filters;
    filters[key] = value;
    this.setState({ filters: filters });
  };

  onFilterDateChange = (value, key) => {
    let filters = this.state.filters;
    let filterTypes = this.state.filterTypes;
    filters[key] = value;
    if (!filterTypes[key]) {
      filterTypes[key] = "before";
    }
    this.setState({ filters: filters, filterTypes: filterTypes });
  };

  applyFilter = () => {
    this.filterDocuments();
  };

  onFilterTypeChange = (key, type) => {
    let filterTypes = this.state.filterTypes;
    filterTypes[key] = type;
    this.setState({ filterTypes: filterTypes });
  };

  onDateTypeFilterChange = type => {
    this.setState({ dateTypeFilter: type });
  };

  onChangeStartDate = date => {
    this.setState({ startDate: date });
  };

  onChangeEndDate = date => {
    this.setState({ endDate: date });
  };

  clearDates = () => {
    this.setState({ dateTypeFilter: "", startDate: "", endDate: "" }, () => {
      this.filterDocuments();
    });
  };

  clearDate = key => {
    let filters = this.state.filters;
    let filterTypes = this.state.filterTypes;
    filters[key] = "";

    filterTypes[key] = "";

    this.setState({ filters: filters, filterTypes: filterTypes });
  };

  setSort = sort => {
    this.setState({ sort: sort }, () => {
      this.filterDocuments();
    });
  };

  toggleMenuOpen = isOpen => {
    this.setState({ menuOpen: isOpen });
    console.log(isOpen)
  };

  handleTagsChange = e => { };

  renderFilerList = () => {
    return (
      <div className={styles.filterContainers}>
        {this.state.documentFields.map((field, idx) => {
          if (field.field_type == "select_options") {
            return (
              <div key={field.key} className={styles.filterContainer}>
                <DocumentFilterItem
                  key={field.key}
                  field={field}
                  active={this.state.filters[field.key] ? 1 : 0}
                  onClose={this.applyFilter}
                  clearFilter={this.clearFilter}
                  toggleMenuOpen={this.toggleMenuOpen}
                >
                  <div className={styles.filterContainerInner}>
                    {this.state.selectOptions[field.key].map(
                      (option, index) => {
                        return (
                          <div
                            key={option.value}
                            className={styles.dropDownSelectOption}
                            onClick={() => {
                              this.onFilterChange(option.value, field.key);
                            }}
                          >
                            <div
                              className={
                                this.state.filters[field.key] == option.value
                                  ? styles.filterActive
                                  : ""
                              }
                            >
                              {option.label}
                            </div>
                            <div className={styles.dropDownOptionCheckMark}>
                              {this.state.filters[field.key] ==
                                option.value && <img src={checkMark} />}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </DocumentFilterItem>
              </div>
            );
          } else if (
            field.field_type == "input" ||
            field.field_type == "textarea"
          ) {
            return (
              <div key={field.key} className={styles.filterContainer}>
                <DocumentFilterItem
                  key={field.key}
                  field={field}
                  active={this.state.filters[field.key] ? 1 : 0}
                  applyFilter={this.applyFilter}
                  onClose={this.applyFilter}
                  clearFilter={this.clearFilter}
                  toggleMenuOpen={this.toggleMenuOpen}
                >
                  <div className={styles.filterContainerInner}>
                    <div className={styles.filterLabel}>
                      {field.label} Contains
                    </div>
                    <UikInput
                      onChange={e => {
                        this.onFilterChange(e.currentTarget.value, field.key);
                      }}
                      value={this.state.filters[field.key]}
                    />
                  </div>
                </DocumentFilterItem>
              </div>
            );
          } else if (field.field_type == "date_time") {
            return (
              <div key={field.key} className={styles.filterContainer}>
                <DocumentFilterItem
                  active={this.state.filters[field.key] ? 1 : 0}
                  applyFilter={this.applyFilter}
                  onClose={this.applyFilter}
                  clearFilter={this.clearDate}
                  field={field}
                  toggleMenuOpen={this.toggleMenuOpen}
                >
                  <div
                    className={styles.filterContainerInner + " " + styles.large}
                  >
                    <div className={styles.dateFilterContainer}>
                      <div className={styles.dateFilterContainerInner}>
                        <div className={styles.dateFilterButtonContainer}>
                          <div
                            key={"before"}
                            className={styles.dropDownSelectOption}
                            onClick={() => {
                              this.onFilterTypeChange(field.key, "before");
                            }}
                          >
                            <div
                              className={
                                this.state.filterTypes[field.key] == "before"
                                  ? styles.filterActive
                                  : ""
                              }
                            >
                              {"Before"}
                            </div>
                            <div className={styles.dropDownOptionCheckMark}>
                              {this.state.filterTypes[field.key] ==
                                "before" && <img src={checkMark} />}
                            </div>
                          </div>
                          <div
                            key={"after"}
                            className={styles.dropDownSelectOption}
                            onClick={() => {
                              this.onFilterTypeChange(field.key, "after");
                            }}
                          >
                            <div
                              className={
                                this.state.filterTypes[field.key] == "after"
                                  ? styles.filterActive
                                  : ""
                              }
                            >
                              {"After"}
                            </div>
                            <div className={styles.dropDownOptionCheckMark}>
                              {this.state.filterTypes[field.key] == "after" && (
                                <img src={checkMark} />
                              )}
                            </div>
                          </div>
                          <div
                            key={"exact"}
                            className={styles.dropDownSelectOption}
                            onClick={() => {
                              this.onFilterTypeChange(field.key, "exact");
                            }}
                          >
                            <div
                              className={
                                this.state.filterTypes[field.key] == "exact"
                                  ? styles.filterActive
                                  : ""
                              }
                            >
                              {"Exact"}
                            </div>
                            <div className={styles.dropDownOptionCheckMark}>
                              {this.state.filterTypes[field.key] == "exact" && (
                                <img src={checkMark} />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className={styles.dateFilterPickerContainer}>

                          <div className={styles.dateLabel}>DATE</div>

                          <DatePicker
                            className="uik-input__input"
                            selected={
                              this.state.filters[field.key]
                                ? moment(
                                  this.state.filters[field.key]
                                ).toDate()
                                : ""
                            }
                            onChange={e => {
                              this.onFilterDateChange(e, field.key);
                            }}
                            placeholderText="Date"
                          />

                        </div>
                      </div>
                    </div>
                  </div>
                </DocumentFilterItem>
              </div>
            );
          } else if (field.field_type === "sequential") {
            return (
              <div key={field.key} className={styles.filterContainer}>
                <DocumentFilterItem
                  key={field.key}
                  field={field}
                  active={this.state.filters[field.key] ? 1 : 0}
                  applyFilter={this.applyFilter}
                  onClose={this.applyFilter}
                  clearFilter={this.clearFilter}
                  toggleMenuOpen={this.toggleMenuOpen}
                >
                  <div className={styles.filterContainerInner}>
                    <div className={styles.filterLabel}>
                      {field.label} Contains
                    </div>
                    <UikInput
                      onChange={e => {
                        this.onFilterChange(e.currentTarget.value, field.key);
                      }}
                      value={this.state.filters[field.key]}
                    />
                  </div>
                </DocumentFilterItem>
              </div>
            );
          }
        })}
        <div key={"tags"} className={styles.filterContainer}>
          <DocumentFilterItem
            key={"tags"}
            field={{ key: "tags", label: "Tags" }}
            active={
              this.state.filters["tags"] &&
                this.state.filters["tags"].length > 0
                ? 1
                : 0
            }
            onClose={this.applyFilter}
            clearFilter={this.clearFilter}
            toggleMenuOpen={this.toggleMenuOpen}
          >
            <div className={styles.filterContainerInner}>
              <Select
                isSearchable={true}
                options={this.state.tags}
                onChange={e => this.onFilterDateChange(e, "tags")}
                isMulti={true}
                value={this.state.filters["tags"]}
                className={"tags_filter"}
              />
            </div>
          </DocumentFilterItem>
        </div>
      </div>
    );
  };

  renderDocumentList = () => {
    const actionsDropDown = ({ onClick }) => {
      return (
        <div
          className={styles.documentsActionsDropDownContainer}
          onClick={onClick}
        >
          <img src={sortIcon} alt="sort_icon" />
        </div>
      );
    };
    return (
      <div id="documents_container">
        <div className={styles.searchFormContainer}>
          <div className={styles.sortContainer}>
            <UikDropdown
              DisplayComponent={actionsDropDown}
              position="bottomRight"
            >
              <UikDropdownItem onClick={e => this.setSort("alpha_asc")}>
                <span
                  className={
                    this.state.sort == "alpha_asc" ? styles.filterActive : ""
                  }
                >
                  A ➝ Z
                </span>
              </UikDropdownItem>

              <UikDropdownItem onClick={e => this.setSort("alpha_desc")}>
                <span
                  className={
                    this.state.sort == "alpha_desc" ? styles.filterActive : ""
                  }
                >
                  Z ➝ A
                </span>
              </UikDropdownItem>
              <UikDropdownItem onClick={e => this.setSort("updated_asc")}>
                <span
                  className={
                    this.state.sort == "updated_asc" ? styles.filterActive : ""
                  }
                >
                  Oldest ➝ Newest
                </span>
              </UikDropdownItem>
              <UikDropdownItem onClick={e => this.setSort("updated_desc")}>
                <span
                  className={
                    this.state.sort == "updated_desc" ? styles.filterActive : ""
                  }
                >
                  Newest ➝ Oldest
                </span>
              </UikDropdownItem>
            </UikDropdown>
          </div>
          <Link to={"/document_libraries"} className={styles.leftIcon}>
            <img
              src={leftIcon1x}
              srcSet={`${leftIcon1x} 1x, ${leftIcon2x} 2x, ${leftIcon3x} 3x`}
            />
          </Link>
          <span>
            <h3 className={`${this.state.searchField ? styles.truncatedTitle : styles.searchTitleDisabled}`}>
              {this.state.library.name}
            </h3>
          </span>
          {!this.state.searchField && (
            <div>
              <Uikon
                className={styles.searchIconDisabled}
                onClick={() => this.handleOpenSearch()}
              >
                search_left
              </Uikon>
            </div>
          )}
          {this.state.searchField && (
            <div>
              <div
                onClick={() => this.handleCloseSearch()}
                className={styles.searchClose}
              >
                &times;
              </div>
              <div className={styles.searchInputContainer}>
                <UikInput
                  autoFocus
                  className={styles.searchInput}
                  placeholder="Search Approved Documents"
                  onChange={e => this.handleDocumentSearch(e)}
                  value={this.state.search}
                />
              </div>
              <Uikon className={styles.searchIcon}>search_left</Uikon>
            </div>
          )}
        </div>
        {this.renderFilerList()}
        {this.state.searchField && (<h3 className={styles.searchTitle}>Search Results</h3>)}
        <div className={styles.documentList}>
          {this.state.documents.map((doc, index) => {
            return (
              <div key={doc.id}>
                <DocumentThumbnail key={index} doc={doc} />
              </div>
            );
          })}
          {this.state.documents.length === 0 && (
            <div className={styles.noDocuments}>No documents found</div>
          )}
          <div className="clear" />
          {this.state.menuOpen && (
            <div className={styles.documentListOver}></div>
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <Container fluid className="users-screen">
        <Header />

        {this.renderDocumentList()}
      </Container>
    );
  }
}

export default Documents;
