import React from "react";
import { Container, Row, Col } from "react-grid-system";
import Header from "../shared/Header/Header";
import SettingsLeftMenu from "../shared/SettingsLeftMenu/SettingsLeftMenu";
import styles from "./PersonalInfo.module.scss";
import { UikDivider } from "@uik";
import PersonalInfoEditForm from "./PersonalInfoEditForm";

export const PersonalInfo = () => {

  return (
    <Container fluid className={styles.container}>
      <Header />
      <Row className={styles.row}>
        <SettingsLeftMenu />
        <Col sm={12} md={5}>
          <div className={styles.block}>
            <div className={styles.content}>
              <div className={styles.heading}>
                <div>Personal Info</div>
                <div className={styles.subHeading}>
                  Make changes to your profile here
              </div>
              </div>
            </div>
            <UikDivider />
              <PersonalInfoEditForm/>
            <UikDivider />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
