import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styles from "./DocumentVersion.module.scss";

class DocumentVersion extends React.Component {
  constructor(props) {
    super();

    this.resizeTimeout = null;

    this.state = {
      docId: props.docId
    };
  }

  render() {
    const isActive =
      (this.props.docId == this.props.version.id && !this.props.versionId) ||
      this.props.versionId == this.props.version.id;
    return (
      <div>
        <div
          className={
            styles.versionHeader + (isActive ? " " + styles.active : "")
          }
          onClick={() =>
            this.props.onClickedVersion(this.props.version.id, this.props.index, this.props.version.file_extension)
          }
        >
          <div
            className={
              styles.versionNamePlateContainer +
              (this.props.version.approved === true ? " " + styles.active : "")
            }
          >
            <span className={styles.versionNamePlate}>
              Version {this.props.index}
            </span>
          </div>
          <span className={styles.versionDate}>
            Uploaded{" "}
            {moment(this.props.version.created_at).format("MMM D, YYYY")}
          </span>
          {isActive && <div className={styles.versionHeaderActive}></div>}
        </div>
      </div>
    );
  }
}

DocumentVersion.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  version: PropTypes.shape({
    id: PropTypes.number,
    version: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.string
  }).isRequired
};

export default DocumentVersion;
