import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styles from "./DocumentVersionMessage.module.scss";

class DocumentVersionMessage extends React.Component {
  constructor(props) {
    super();

  }
  render() {
    return (
      <div className={styles.versionMessageContainer}>
        {this.props.message}
        <div
          className={styles.closeVersion}
          onClick={() => this.props.closeVersion()}>
          x
          </div>
      </div>
    );
  }
}

DocumentVersionMessage.propTypes = {
  message: PropTypes.string
};

export default DocumentVersionMessage;
