import axios from "./axios-client";

import jwtDecode from "jwt-decode";

import security from "../services/Security";

class HttpClient {
  constructor() {
    console.log("pathname");
    console.log(window.location.pathname);

    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
      // Do something with response data
      return response;
    }, function (error) {
      // Do something with response error
      security.checkAuth(error);

      console.log("error", error);
      return Promise.reject(error);
    });
  }

  getConfig = () => {
    const token = security.getToken();
    if (token) {
      this.currentUserRole = jwtDecode(token).user_role;

      return {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    } else {
      return {}
    }
  }

  get = (endpoint) => {
    return axios.get(endpoint, this.getConfig());
  }

  post = (endpoint, data) => {
    return axios.post(endpoint, data, this.getConfig());
  }

  put = (endpoint, data) => {
    return axios.put(endpoint, data, this.getConfig());
  }

  delete = (endpoint, data) => {
    return axios.delete(endpoint, this.getConfig());
  }

}

export default new HttpClient();
