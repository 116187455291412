import React, {useState, useEffect} from "react";
import moment from "moment";
import { CSSTransition } from "react-transition-group";

import CloseSVG from "../../images/icons/png/close.png";
import CommentSVG from "../../images/icons/svg/comment.svg";
import Checkmark from "../Common/Checkmark";
import httpClient from "../../lib/HttpClient";
import RightArrowSVG from "../../images/icons/svg/Right-Arrow-Dark.svg";
import SortButtonSVG from "../../images/icons/png/sort-icon-white.png";
import CheckmarkSVG from "../../images/icons/svg/Check_thick.svg";

// import "./ReferencePanelAnimation.css";
import styles from "./ConversationsPanel.module.scss";
import { UikInput } from "@uik";

const sortbyOptions = ["page","resolved"]


const ConversationsPanel = props => {

    const {conversations, closeConversationsPanel, showGeneralComments, showAnnotationComments, reloadConversations, readOnly} = props;

    const [searchValue,setSearchValue] = useState("");
    const [filteredConversations,setFilteredConversations] = useState(conversations);
    const [showSortbyOptions, setShowSortbyOptions] = useState(false);
    const [sortby,setSortby] = useState("page");
    const [allResolved,setAllResolved] = useState(true);
    const [noneResolved,setNoneResolved] = useState(true);

    useEffect(()=> {
        setFilteredConversations(conversations);
        setSearchValue("");
        establishConversationStatus(conversations)
    },[conversations]);

    const establishConversationStatus = async conversations => {
        let conversationResolved = true
        let conversationNotResolved = true
        await conversations.map((conversation) => {
            if (conversation.comments.length > 0 ) {
                if(conversation.status === "unresolved") {
                    conversationResolved = false
                    return setAllResolved(null);
                } else if (conversation.status === "resolved"){
                    conversationNotResolved = false
                    return setNoneResolved(null)
                }
            }
        })
        if (conversationResolved) {
            setAllResolved(true);
        } else if (conversationNotResolved) {
            setNoneResolved(true);
        }
    }

    const toggleConversationStatus = conversation => {
        let status = "resolved";
        if (conversation.status === "resolved") {
            status = "unresolved";
        }

        httpClient.put(`/conversations/${conversation.xfdf_highlight_id || conversation.block_identifier}.json`,{
            conversation: {
                status
            }
        }).then(res => {
            reloadConversations();
        })
    }

    const conversationClicked = convo => {
        if (convo.block_identifier) {
            showGeneralComments();
        } else {
            annotationConversationClicked(convo.page.page_number-1,convo);
        }
    }

    const renderConversation = convo => {

        return (
            <div onClick={() => conversationClicked(convo)} className={styles.conversation}>
                <div className={styles.metadata}>
                    <div className={styles.leftContainer}>
                        <img className={styles.commentSVG} src={CommentSVG}/>
                        <div className={styles.numberComments}>
                            {`${convo.comments.length}`}
                        </div>
                        {convo.comments.length > 0 &&
                            <div className={styles.nameDate}>
                                <div className={styles.dotSeparator}>
                                    &#9679;
                                </div>
                                <div className={styles.name}>
                                    {convo.comments[0].user.first_name} {convo.comments[0].user.last_name}
                                </div>
                                <div className={styles.dotSeparator}>
                                    &#9679;
                                </div>
                                <div className={styles.date}>
                                    {moment(convo.comments[convo.comments.length-1].commented_at).calendar(null, {
                                        lastDay: "[Yesterday]",
                                        lastWeek: "MMM D",
                                        sameDay: "LT",
                                        sameElse: "MMM D"
                                    })}
                                    <div className={styles.fullDateTimeContainer}>
                                        <div className={styles.fullDateTime}>
                                            {moment(convo.comments[convo.comments.length-1].commented_at).format("MMMM D, YYYY [at] h:mma")}
                                        </div>
                                        <div className={styles.bottomArrow} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={styles.rightContainer}>
                        {convo.comments.length === 0 ?
                            <div className={styles.noCommentContainer}>
                                <span>Add a comment</span>
                                <div className={styles.arrowRight}>
                                    <img src={RightArrowSVG}/>
                                </div>
                            </div> :
                            <div className={styles.checkmarkContainer}>
                                {readOnly &&
                                    <div className={styles.toggleDisabledContainer}>
                                        <div className={styles.disabledText}>
                                            {`${convo.status === "resolved" ? "Resolved": "Not Resolved"}`} <span className={`${styles.dotSeparator} ${styles.alignDot}`}>&#9679;</span> Read only
                                        </div>
                                        <div className={styles.bottomArrow} />
                                    </div>
                                }
                                <Checkmark disabled={readOnly} status={convo.status} onClick={() => toggleConversationStatus(convo)}/>
                            </div>
                        }
                    </div>
                </div>
                <div className={styles.latestCommentText}>
                    {convo.comments.length > 0 && 
                    convo.comments[0].body}
                </div>
            </div>
        )

    }

    const renderConversationsList = () => {

        if (sortby === "page"){

            let conversationsObj = {};
            let generalCommentsConversation = null;

            filteredConversations.forEach(conv => {
                if (conv.block_identifier) {
                    generalCommentsConversation = conv;
                }
                else {
                    if (!conversationsObj[conv.page.page_number]){
                        conversationsObj[conv.page.page_number] = [];
                    }
                    conversationsObj[conv.page.page_number].push(conv);
                }
            })


            return (
                <div className={styles.conversationsContainer}>
                    {generalCommentsConversation &&
                    <div className={styles.page}>
                        <div className={styles.title}>
                            general comments
                        </div>
                        <div className={styles.dividerContainer} />
                        {renderConversation(generalCommentsConversation)}
                        <div className={styles.dividerContainer} />
                    </div>}
                    {Object.entries(conversationsObj).map(([key,conversations],index)=>
                        <div key={index} className={styles.page}>
                            <div className={styles.title}>
                                {`page ${key}`}
                            </div>
                            <div className={styles.dividerContainer} />
                            {conversations.map(convo =>
                                <div>
                                    {renderConversation(convo)}
                                    <div className={styles.dividerContainer} />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )
        }
        else if (sortby === "resolved") {   

            return (
                <div className={styles.conversationsContainer}>
                    {filteredConversations.length > 0 &&
                        <div className={styles.page}>
                            <div className={styles.title}>
                                not resolved
                            </div>
                            {allResolved === true &&
                            <div>
                                <div className={styles.dividerContainer} />
                                <div className={styles.messageContainer}>
                                    <div className={styles.emptyMessage}>All comments are resolved.</div>
                                </div>
                            </div>
                            }
                            <div className={styles.dividerContainer} />
                            {filteredConversations.map(convo =>
                                convo.comments.length > 0 &&
                                <CSSTransition
                                    in={filteredConversations.find(conv => convo.id === conv.id && conv.status === "unresolved" )}
                                    unmountOnExit
                                    classNames={"conversation"}
                                    timeout={700}
                                >
                                    <div>
                                        {renderConversation(convo)}
                                        <div className={styles.dividerContainer} />
                                    </div>
                                </CSSTransition>
                            )}
                        </div>
                    }
                    {filteredConversations.length > 0 &&
                        <div className={styles.page}>
                            <div className={styles.title}>
                                resolved
                            </div>
                            {noneResolved === true &&
                            <div>
                                <div className={styles.dividerContainer} />
                                <div className={styles.messageContainer}>
                                    <div className={styles.emptyMessage}>No comments are resolved.</div>
                                </div>
                            </div>
                            }
                            <div className={styles.dividerContainer} />
                            {filteredConversations.map(convo =>
                                convo.comments.length > 0 &&
                                <CSSTransition
                                    in={filteredConversations.find(conv => convo.id === conv.id && conv.status === "resolved" )}
                                    unmountOnExit
                                    classNames={"conversation"}
                                    timeout={700}
                                >
                                    <div>
                                        {renderConversation(convo)}
                                        <div className={styles.dividerContainer} />
                                    </div>
                                </CSSTransition>
                            )}
                        </div>
                    }
                </div>
            )


        }
    }

    const onSearchChange = e => {
        const filteredConversations = conversations.filter(conv => {
            if (e.target.value === ""){
                return true;
            } else{
                return conv.comments.length > 0 && conv.comments[conv.comments.length-1].body.toLowerCase().includes(e.target.value.toLowerCase())
            }
        });
        setSearchValue(e.target.value);
        setFilteredConversations(filteredConversations);
    }

    const annotationConversationClicked = (key,conv) => {
        const webViewerEl = document.getElementById("webViewer");
        const instance = window.WebViewer.getInstance(webViewerEl);
        const annotation = instance.annotManager.getAnnotationById(conv.xfdf_highlight_id);

        showAnnotationComments(parseInt(key),conv.xfdf_highlight_id,false)

        instance.annotManager.deselectAllAnnotations();
        instance.annotManager.selectAnnotation(annotation);
        instance.docViewer.setCurrentPage(conv.page.page_number);
    }

    return (
        <div className={styles.conversationsPanel} onClick={() => setShowSortbyOptions(false)}>
            <div className={styles.header}>
                <div className={styles.titleContainer}>
                    <div className={styles.leftContainer}>
                        <div className={styles.title}>
                            Comments
                        </div>
                    </div>
                    <div className={styles.rightContainer}>
                        <div className={styles.sortContainer}>
                            <img className={styles.sortButton} src={SortButtonSVG} onClick={e => {e.stopPropagation(); setShowSortbyOptions(!showSortbyOptions)}}/>
                            {showSortbyOptions && 
                                <div className={styles.sortOptions}>
                                    {sortbyOptions.map(option => 
                                        <div className={`${styles.option} ${option === sortby ? styles.optionSelected: ``}`} onClick={() =>  setSortby(option)}>
                                            <span>{option}</span>
                                            {option === sortby &&
                                                <div className={styles.checkmark}>
                                                    <img src={CheckmarkSVG}/>
                                                </div>
                                            }
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                        <div onClick={closeConversationsPanel} className={styles.closeButton}>
                            <img className={styles.closeIcon}src={CloseSVG}/>
                        </div>
                    </div>
                </div>
                <div className={styles.searchInputContainer}>
                    <UikInput
                        placeholder="Search Comments"
                        className={styles.searchInputField}
                        onChange={onSearchChange}
                        value={searchValue}
                    >
                    </UikInput>
                </div>
            </div>
            <div className={styles.body}>
                {filteredConversations && renderConversationsList(conversations)}
            </div>
        </div>
    )
}

export default ConversationsPanel;
