import React, { useState, useEffect } from "react";
import ChatIcon from "../../../images/icons/svg/icon_chat.svg";
import ChatIconDark from "../../../images/icons/svg/icon_chat_dark.svg";
import ChatIconActive from "../../../images/icons/svg/icon_chat_active.svg";
import CloseIcon from "../../../images/icons/svg/hubspot-close.svg";
import "./HubspotChat.scss";

export const HubspotChat = (props) => {
  const {darkMode} = props;
  const [showChat, setShowChat] = useState(false);

  useEffect(() => {
    document.dispatchEvent(new CustomEvent("toggleHSChat", {
      detail: {
        visible: showChat
      }
    }));
  }, [showChat])

  const icon = darkMode ? ChatIconDark : ChatIcon;
  const dismiss = darkMode ? ChatIconActive : CloseIcon;

  return (
    <div className={`hs-chat${!showChat ? ` hs-chat__closed` : ``} ${darkMode && !showChat ? `hs-chat-dark-mode` : ``}`} onClick={() => setShowChat(!showChat)}>
      <img src={!showChat ? icon : dismiss} />
    </div>
  )
}
