import React from "react";
import PropTypes from "prop-types";
import { Link, Router, Route } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import { createForm, formShape } from "rc-form";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { Line } from "rc-progress";
import history from "../../../history";
import axios from "../../../lib/axios-client";
import httpClient from "../../../lib/HttpClient";

import referenceIcon from "../../../images/icons/16px/link.svg";
import Checkmark from "../../../images/icons/png/checkmark.png";
import Warning from "../../../images/icons/png/warning.svg";

import security from "../../../services/Security";

/* UI Kit */
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  Uikon,
  UikWidget,
  UikHeadline,
  UikHeadlineDesc,
  UikDivider,
  UikSelect
} from "@uik";
import "@uik/styles.css";
import "../../../font.scss";

import Spinner from "../../Common/Spinner";
import ButtonPrimary from "../../Common/Buttons/ButtonPrimary";
import Checkbox from "../../Common/Checkbox";

import styles from "./DraftUpdateForm.module.scss";

/* Variables */

class DraftUpdateFormBuild extends React.Component {
  constructor() {
    super();
    this.state = {
      mainDocumentPercent: 0,
      mainDocumentFilename: "",
      mainDocumentTitle: "",
      mainDocument: null,
      referenceFiles: [],
      errors: {},
      error: false,
      pdfOnlyMessage:
        "Only PDF, DOC(X), XLS(X) and PPT(X) files are supported at this time.",
      referenceDocumentError: false,
      notes: "",
      showNotes: true
    };
  }

  onMainDocumentDrop = (acceptedFiles, rejectedFiles) => {
    const token = security.getToken();
    const { docId } = this.props;

    const postConfig = {
      headers: {
        Authorization: "Bearer " + token
      },
      onUploadProgress: progressEvent => {
        const mainDocumentPercent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (mainDocumentPercent >= 100) {
          this.setState({ mainDocumentPercent: 100 });
        } else {
          this.setState({ mainDocumentPercent });
        }
      }
    };

    const documentFormData = new FormData();
    documentFormData.append("document[document_file]", acceptedFiles[0]);

    this.setState({ mainDocumentPercent: 0 });
    this.setState({ mainDocumentFilename: acceptedFiles[0].name });

    axios
      .put(`/documents/${docId}.json`, documentFormData, postConfig)
      .then(response => {
        this.setState({
          mainDocument: response.data,
          error: false,
          errors: {}
        });
      })
      .catch(error => {
        security.checkAuth(error);
        console.log("error", error);

        this.setState({
          mainDocumentPercent: 0,
          mainDocument: {},
          errors: error.response.data,
          error: true
        });
      });
  };

  renderMainDocumentPanel = () => {
    return (
      <div>
        <span className={styles.uploaderIcon}>
          <Uikon>cloud_up</Uikon>
        </span>
        <span className={styles.uploaderPlaceholder}>
          Drop files here to upload
        </span>
        <UikButton transparent className={styles.uploaderButton}>
          Browse
        </UikButton>
      </div>
    );
  };

  renderMainDocumentUploader = () => {
    return (
      <div className={styles.mainContentUploader}>
        <Dropzone onDrop={this.onMainDocumentDrop} multiple={false}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames("dropzone", {
                  "dropzone--isActive": isDragActive
                })}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <div>
                    <span className={styles.uploaderIcon}>
                      <Uikon>cloud_up</Uikon>
                    </span>
                    <span className={styles.uploaderPlaceholder}>
                      Drop approval document here
                    </span>
                  </div>
                ) : (
                    <div>
                      <span className={styles.uploaderIcon}>
                        <Uikon>cloud_up</Uikon>
                      </span>
                      <span className={styles.uploaderPlaceholder}>
                        Drop files here to upload
                    </span>
                      <UikButton transparent className={styles.uploaderButton}>
                        Browse
                    </UikButton>
                    </div>
                  )}
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  };

  renderMainDocumentUploaderProgress = () => {
    return (
      <div className={styles.mainContentUploading}>

        <div className={styles.innerContentUploading}>
          <span className={styles.uploaderIcon}>
            <Uikon>attachment</Uikon>
          </span>
          {this.state.error && (
            <span className={styles.uploadError}>
              {this.state.pdfOnlyMessage}
            </span>
          )}

          <span className={styles.uploadingFilename}>
            {this.state.mainDocumentFilename}
          </span>
          {this.state.mainDocumentPercent == 100 && !this.state.error && (
            <span className={styles.uploaderIconCheck}>
              <img src={Checkmark} className={styles.checkMark} />
            </span>
          )}
          {this.state.error && (
            <span className={styles.uploaderIconError}>
              <img src={Warning} className={styles.warning} />
            </span>
          )}
          {this.state.mainDocumentPercent < 100 && (
            <Line
              className={styles.uploadProgress}
              percent={this.state.mainDocumentPercent}
              strokeWidth="1"
              strokeColor="#1b8c96"
              strokeLinecap="square"
            />
          )}
        </div>
      </div>
    );
  };

  goToDocument = e => {
    e.preventDefault();
    const { docId } = this.props;
    history.push(`/drafts/${docId}`);
  };

  goToNewDocument = e => {
    e.preventDefault();
    const { id } = this.state.mainDocument;
    if (this.state.showNotes) {
      httpClient.put(`/documents/${id}.json`, {
        document: {
          notes: this.state.notes
        }
      }).then(() => {
        history.push(`/drafts/${id}`);
      })
    } else {
      history.push(`/drafts/${id}`);
    }
  };

  handleNotesChange = e => {
    this.setState({ notes: e.target.value });
  };

  render() {
    const { getFieldError, getFieldDecorator } = this.props.form;

    return (
      <div>
        <Container fluid className={styles.draftUploadForm}>
          <UikFormInputGroup>
            <span>Upload your main content</span>
            {this.state.error && this.renderMainDocumentUploader()}
            {!this.state.mainDocumentFilename &&
              this.renderMainDocumentUploader()}
            {this.state.mainDocumentFilename.length > 0 &&
              this.renderMainDocumentUploaderProgress()}
          </UikFormInputGroup>
          {this.props.showNotes && (
            <Row className={styles.notesInput}>
              <Col sm={12}>
                <div>
                  <Checkbox
                    checked={true}
                    label="Notify reviewers"
                    onChange={e => this.setState(prevState => ({ ...prevState, showNotes: !prevState.showNotes }))}
                  />
                </div>
                {this.state.showNotes &&
                  <textarea
                    className={`uik-input__input ${styles.notesTextarea}`}
                    style={{ width: "100%" }}
                    rows="5"
                    value={this.state.notes}
                    onChange={e => this.handleNotesChange(e)}
                    placeholder="Type a message to reviewers."
                  ></textarea>
                }
              </Col>
            </Row>
          )}
        </Container>
        <Container className={styles.draftUploadFormActions}>
          {(this.state.mainDocument || this.state.mainDocumentPercent != 100) &&
            <Row className={styles.buttonRow}>
              <ButtonPrimary
                original
                disabled={!(this.state.mainDocument && this.state.mainDocumentPercent == 100)}
                text="Next"
                onClick={e => this.goToNewDocument(e)}
              />
              {!(this.state.mainDocument && this.state.mainDocumentPercent == 100) &&
                <ButtonPrimary
                  transparent
                  text="Cancel"
                  onClick={e => this.goToDocument(e)}
                />
              }
            </Row>}
          {!this.state.mainDocument && this.state.mainDocumentPercent == 100 && (
            <div className={styles.processingContainer}>
              <Spinner text={"Processing..."} />
            </div>
          )}
        </Container>
      </div>
    );
  }
}

DraftUpdateFormBuild.propTypes = {
  docId: PropTypes.number
};

const DraftUpdateForm = createForm()(DraftUpdateFormBuild);
export default DraftUpdateForm;
