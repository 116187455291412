/*
 * Stamp Tool
 *
 * Function that creates a Stamp tool object.
 * This PDFTron tool object can be registered for a tool in the header.
 *
 * A PDFTron tool object is based on their API:
 * https://www.pdftron.com/api/web/Tools.Tool.html
 *
 */

function createStampTool(instance, stampImage, type, closeConversation) {
  var docViewer = instance.docViewer;
  var annotManager = instance.annotManager;
  var Annotations = instance.Annotations;
  var Tools = instance.Tools;

  // Custom stamp tool constructor that inherits generic annotation create tool
  var CustomStampCreateTool = function () {
    // Inherit generic annotation create tool
    Tools.GenericAnnotationCreateTool.call(this, docViewer, Annotations.StampAnnotation);
    delete this.defaults.StrokeColor;
    delete this.defaults.FillColor;
    delete this.defaults.StrokeThickness;
    delete this.defaults.Opacity;
  };

  CustomStampCreateTool.prototype = new Tools.GenericAnnotationCreateTool();

  // Override mouseLeftDown
  CustomStampCreateTool.prototype.mouseLeftDown = Tools.AnnotationSelectTool.prototype.mouseLeftDown;

  // Override mouseMove
  CustomStampCreateTool.prototype.mouseMove = Tools.AnnotationSelectTool.prototype.mouseMove;

  // Override mouseLeftUp
  CustomStampCreateTool.prototype.mouseLeftUp = function (e) {
    Tools.GenericAnnotationCreateTool.prototype.mouseLeftDown.call(this, e);
    var annotation;

    if (this.annotation) {
      if (closeConversation) {
        closeConversation();
      }

      var width = 20;
      var height = 28;
      var rotation = this.docViewer.getCompleteRotation(this.annotation.PageNumber) * 90;
      this.annotation.Rotation = rotation;

      if (rotation === 270 || rotation === 90) {
        var t = height;
        height = width;
        width = t;
      }

      // We have to keep this a 1, otherwise PDFTron will try to optimize the data:image/svg+xml,base64 to data:image/png,base64
      // 1 is the only correct number if you want to retain your data uri.
      this.annotation.Opacity = 1;
      this.annotation.ImageData = stampImage;
      //this.annotation.setImageData(stampImage, false);
      this.annotation.NoResize = true;
      this.annotation.Width = width;
      this.annotation.Height = height;
      this.annotation.X -= width / 2;
      this.annotation.Y -= height / 2;
      this.annotation.MaintainAspectRatio = true;
      this.annotation.setCustomData('highlight-type', type);
      annotation = this.annotation;
    }

    Tools.GenericAnnotationCreateTool.prototype.mouseLeftUp.call(this, e);

    if (annotation) {
      annotManager.redrawAnnotation(annotation);
      instance.setToolMode('AnnotationEdit');
    }
  };

  return new CustomStampCreateTool();
};

export default { createStampTool }
