/* External libraries */
import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import history from "../../history";
import httpClient from "../../lib/HttpClient";

import security from "../../services/Security";
import notificationService from "../../services/Notifications";

/* UI Kit */
import { UikInput, UikButton, Uikon } from "@uik";
import "@uik/styles.css";
import "../../font.scss";

/* Papercurve Components */
import Header from "../shared/Header/Header";
import SettingsLeftMenu from "../shared/SettingsLeftMenu/SettingsLeftMenu";
import UserListItem from "./UserListItem";
import UserListItemsHeader from "./UserListItemsHeader";
import UserEditForm from "./UserEditForm";
import UserInviteForm from "./UserInviteForm";
import UserReactivateForm from "./UserReactivateForm";

/* Assets */
import "./Users.css";

/* Variables */

class Users extends React.Component {
  constructor() {
    super();

    this.state = {
      users: [],
      filteredUsers: [],
      selectedUser: null,
      userSearchQuery: null,
    };
  }

  componentWillMount = () => {
    this.loadUsers();
    httpClient.get('/2fa/client_status')
      .then(res => {
        let client2FAEnabled = false;
        if (res.data.twofa_enabled) {
          client2FAEnabled = true;
        }
        this.setState({ client2FAEnabled });
      })
  };

  loadUsers = () => {
    const selectedUserId = this.props.match.params.id;
    const currentUserRole = security.getUserRole();

    // Only Authors and Admins can see users screen
    if (currentUserRole !== "author" && currentUserRole !== "admin") {
      history.push("/drafts");
    }

    httpClient.get("/users.json").then((response) => {
      this.setState({
        users: response.data,
        filteredUsers: response.data,
      });
      this.state.users.map((user, index) => {
        if (user.id == selectedUserId) {
          this.setState({ selectedUser: user });
        }
      });
    });
  };

  componentWillReceiveProps = (nextProps) => {
    const selectedUserId = nextProps.match.params.id;
    this.state.users.map((user, index) => {
      if (user.id == selectedUserId) {
        this.setState({ selectedUser: user });
        console.log("changing to user", user);
      }
    });
  };

  renderUserList = () => {
    const selectedUserId = this.props.match.params.id;
    const letterHeaders = [];
    return (
      <div>
        {this.state.filteredUsers.map((user, index) => {
          if (user.hidden) {
            return;
          }
          const letter = user.first_name.charAt(0).toUpperCase();
          const headerExists = letterHeaders.includes(letter);

          if (!headerExists) {
            letterHeaders.push(letter);
          }
          return (
            <div key={user.id}>
              {!headerExists && <UserListItemsHeader letter={letter} />}
              <UserListItem user={user} selectedUserId={selectedUserId} />
            </div>
          );
        })}
      </div>
    );
  };

  renderUserEditForm = () => {
    return (
      <div>
        {this.state.selectedUserId != "invite" &&
          this.state.selectedUser &&
          this.state.selectedUser.active !== false && (
            <UserEditForm
              key={this.state.selectedUser.id}
              reloadUsers={() => this.loadUsers()}
              onDelete={() => this.setState({ selectedUser: null })}
              client2FAEnabled={this.state.client2FAEnabled}
              user={this.state.selectedUser}
            />
          )}

        {this.state.selectedUserId != "invite" &&
          this.state.selectedUser &&
          this.state.selectedUser.active === false && (
            <UserReactivateForm
              key={this.state.selectedUser.id}
              onReactivate={() => {
                this.loadUsers();
              }}
              user={this.state.selectedUser}
            />
          )}
      </div>
    );
  };

  handleUserInvite = (userFormData) => {
    httpClient
      .post("/users.json", userFormData)
      .then((response) => {
        console.log("invited!", response);
        history.push("/users");
        this.loadUsers();
        notificationService.addNotification(
          "User invited",
          "New user has been added.",
          "success"
        );
      })
      .catch((e) => {
        let message = "";
        if (e.response && e.response.data) {
          for (let key in e.response.data) {
            message += `${key.charAt(0).toUpperCase() + key.slice(1)}  ${
              e.response.data[key]
              }`;
          }
        }
        notificationService.addNotification("Error", message, "danger");
      });
  };

  renderUserInviteForm = () => {
    return (
      <div>
        <UserInviteForm handleUserInvite={this.handleUserInvite} />
      </div>
    );
  };

  handleUserSearch = (e) => {
    e.preventDefault();
    let users = this.state.users;
    let userSearchQuery = e.target.value.toLowerCase();

    users = users.filter(function (user) {
      let matchingUser = false;
      if (
        user.full_name.toLowerCase().indexOf(userSearchQuery) != -1 ||
        user.email.toLowerCase().indexOf(userSearchQuery) != -1
      ) {
        matchingUser = true;
      }
      return matchingUser;
    });
    this.setState({ filteredUsers: users });
  };

  render() {
    return (
      <Container fluid className="users-screen">
        <Header />
        <Row className="users-screen__container">
          <SettingsLeftMenu />
          <Col sm={12} md={5} className="users-screen-user-list-container">
            <div className="users-screen-user-list">
              <div className="users-screen-user-search">
                <Uikon>search_left</Uikon>
                <div className="user-search-input-container">
                  <UikInput
                    className="users-screen-user-search-input"
                    placeholder="Search User"
                    onChange={(e) => this.handleUserSearch(e)}
                  />
                </div>
                <div className="user-invite-button-container">
                  <Link to="/users/invite">
                    <UikButton>Create User</UikButton>
                  </Link>
                </div>
              </div>
              {this.renderUserList()}
            </div>
          </Col>
          <Col sm={12} md={5} className="users-screen-user-form-container">
            {this.props.match.params.id == "invite"
              ? this.renderUserInviteForm()
              : this.renderUserEditForm()}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Users;
