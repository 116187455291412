import React from 'react';
import { Link, Router, Route } from 'react-router-dom';
import { Container, Row, Col } from 'react-grid-system';
import history from "../../../history";
import styles from './DraftUpload.module.scss'

/* Papercurve Components */
import Header from '../../shared/Header/Header';
import DraftUploadForm from './DraftUploadForm';

import security from "../../../services/Security";

/* Variables */


class DraftUpload extends React.Component {
  componentWillMount = () => {
    const currentUserRole = security.getUserRole();

    // Only Authors and Admins can upload and create drafts.
    if (currentUserRole !== 'author' && currentUserRole !== 'admin') {
      history.push("/drafts");
    }
  }

  render() {
    return (
      <Container fluid className={styles.draftUpload}>
        <Header />
        <Row>
          <Col sm={12}>
            <div className={styles.draftUploadHeading}>
              <h1>Upload your content</h1>
              <span>Fill in your project name and upload your file.</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <DraftUploadForm />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default DraftUpload;
