import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import pluralize from "pluralize";
import styles from "./DraftListItem.module.scss";

import Spinner from "../Common/Spinner";

import errorIcon from " ../../images/icons/16px/error.svg";

const content = approval => (
  <div className={styles.draftListItem}>
    <div className={styles.draftListItemInner}>
      <div className={styles.documentTitle}>{approval.document.title}</div>
      {approval.document.pending_approvals > 0 && (
        <div className={styles.pendingApprovals}>
          Waiting for {approval.document.pending_approvals} approval(s)
        </div>
      )}

      {!approval.document.rejected &&
        !approval.document.approvable &&
        !approval.document.pending_approvals && (
          <div className={styles.pendingApprovals}>No pending approvals</div>
        )}
      <div className={styles.wrapper}>
        {approval.document.due_date && (
          <div className={styles.dueDate}>
            {moment().isAfter(moment(approval.document.due_date), 'day') &&
              !approval.document.approvable && (
                <span className={styles.warningBadge}>OverDue</span>
              )}
            {!(
              moment() > moment(approval.document.due_date) &&
              !approval.document.approvable
            ) && <span>Due </span>}
            <span>{moment(approval.document.due_date).format("MMMM Do")}</span>
          </div>
        )}
        {!approval.document.due_date && (
          <div className={styles.dueDate}>No Due Date</div>
        )}

        {approval.document.approvable && (
          <div className={styles.approvedBadge}>Everyone Approved</div>
        )}
        {approval.document.submit_new_version && (
          <div className={styles.rejectedBadge}>Submit new version</div>
        )}
      </div>
    </div>
    <div className="clear"></div>
  </div>
);

const DraftListItem = ({ approval }) => (
  <div>
    <Link to={`/drafts/${approval.document.id}`}>{content(approval)}</Link>
  </div>
);

DraftListItem.propTypes = {
  approval: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
};

export default DraftListItem;
