import React from "react";
import PropTypes from "prop-types";
import styles from "./RefPanel.module.scss";
import httpClient from "../../lib/HttpClient";
import history from "../../history";

import referenceIcon from '../../images/icons/16px/link.svg';

/* UI Kit */
import {
  Uikon,
  UikButton,
  UikDivider
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";

/* Variables */


class RefPanel extends React.Component {
  constructor() {
    super();

    this.state = {
    };
  }

  addReference = () => {
    const {
      docId,
      documentBlockId,
      referenceBlockId,
      parentDocId,
      pageNumber
    } = this.props;

    const parentDocPageNumber = documentBlockId.split('-')[2];

    const referenceData = {
      reference: {
        document_id: parseInt(docId),
        page_number: parseInt(pageNumber),
        block_identifier: referenceBlockId
      },
      document_reference: {
        document_id: parseInt(parentDocId),
        page_number: parseInt(parentDocPageNumber),
        block_identifier: documentBlockId
      }
    };

    httpClient
      .post(
        '/references.json',
        referenceData
      )
      .then(response => {
        history.push(`/drafts/${this.props.parentDocId}`);
      });
  }

  render() {
    return (
      <div className={styles.selectedTextPanel}>
        <div className={styles.sidePanelHeader}>
          <div className={styles.selectedTextIconContainer}>
            <span className={styles.selectedTextIcon}>
              <img src={referenceIcon} />
            </span>
          </div>
          <span>References</span>
          <span
            className={styles.closeComment}
            onClick={() => this.props.close()}>
            &times;
          </span>
        </div>
        <div className={styles.sidePanelOptions}>
          <div>
            <UikButton
              transparent
              onClick={() => this.addReference()}
            >
              Add Reference
            </UikButton>
          </div>
        </div>
      </div>
    );
  }
}

RefPanel.propTypes = {
  close: PropTypes.func,
  documentBlockId: PropTypes.string,
  referenceBlockId: PropTypes.string,
  docId: PropTypes.number,
  parentDocId: PropTypes.number,
  pageNumber: PropTypes.string
}

export default RefPanel;
