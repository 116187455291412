import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";
import styles from "./DocumentVersionsPanel.module.scss";
import axios from "../../lib/axios-client";

/* Papercurve Components */
import DocumentVersion from "./DocumentVersion";

/* UI Kit */
import {
  Uikon,
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikDivider
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";

/* Variables */
const cookies = new Cookies();

class DocumentVersionsPanel extends React.Component {
  constructor(props) {
    super();

    this.resizeTimeout = null;

    const token = cookies.get("jwt");
    const user = jwtDecode(token);

    this.state = {
      currentUserId: user.user_id,
      docId: props.docId,
      versionId: props.versionId
    };
  }

  componentWillMount = () => {};

  componentDidMount = () => {
    window.addEventListener("resize", this.onResize);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  renderVersions = () => {
    return this.props.versions.map((i, j) => (
      <div key={i.id} className={styles.versions}>
        <DocumentVersion
          version={i}
          index={this.props.versions.length - j}
          currentUserId={this.state.currentUserId}
          docId={this.state.docId}
          versionId={this.props.versionId}
          onClickedVersion={this.props.onClickedVersion}
        />
      </div>
    ));
  };

  render() {
    return (
      <div id="versionsPanel" className={styles.versionsPanel}>
        <div className={styles.versionsListHeader} id="versionslistheader">
          <span className={styles.sidePanelHeadingTitle}>Versions</span>
          <span
            className={styles.closeVersions}
            onClick={() => this.props.onClickCloseVersions()}
          >
            &times;
          </span>
        </div>
        <div
          className={styles.versionsList}
          id="versionsList"
        >
          <div>{this.props.versions.length > 0 && this.renderVersions()}</div>
        </div>
      </div>
    );
  }
}

DocumentVersionsPanel.propTypes = {
  closeVersions: PropTypes.func,
  docId: PropTypes.number
};

export default DocumentVersionsPanel;
