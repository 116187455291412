/* External libraries */
import React from "react";
import { Container, Row, Col, Hidden } from "react-grid-system";
import { createForm, formShape } from "rc-form";
import Button from "../Common/Buttons/ButtonPrimary";

import axios from "../../lib/axios-client";
import history from "../../history";
import HubspotChat from "../Common/HubspotChat";
import httpClient from "../../lib/HttpClient"

/* UI Kit */
import { UikFormInputGroup, UikInput, UikButton } from "@uik";
import "@uik/styles.css";

/* Papercurve Components */

/* Assets */
import styles from "./PasswordCreate.module.scss";
import "./PasswordCreate.scss";
import papercurveLogo from "../../images/logos/papercurve-logo-colour.svg";
import loginImageDesktop1x from "../../images/authentication/images/login-image-desktop.png";
import loginImageDesktop2x from "../../images/authentication/images/login-image-desktop@2x.png";
import loginImageDesktop3x from "../../images/authentication/images/login-image-desktop@3x.png";

class PasswordCreateBuild extends React.Component {
  constructor() {
    super();
    this.state = {
      invalidPassword: false
    };
  }

  componentWillMount = () => {
    this.setState({ resetPasswordToken: this.props.match.params.token });
    httpClient
    .get(`/password/check_reset_password_token/${this.props.match.params.token}`)
    .then(response => {
      console.log('response', response);
    }).catch(() => {
      history.replace({
        pathname: `/passwordlinkexpired`
      })
    });

    this.passwordDecorator = this.props.form.getFieldDecorator("password", {
      initialValue: "",
      rules: [
        {
          required: true,
          message: "Please enter your desired password."
        },
        {
          min: 6,
          message: "Must be at least 6 characters."
        }
      ]
    });

    this.passwordConfirmationDecorator = this.props.form.getFieldDecorator(
      "passwordConfirmation",
      {
        initialValue: "",
        rules: [
          {
            required: true,
            message: "Please re-enter your password."
          },
          {
            min: 6,
            message: "Must be at least 6 characters."
          },
          {
            validator: this.compareToFirstPassword
          }
        ]
      }
    );
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("Passwords must match.");
    } else {
      callback();
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      this.setState({ invalidPassword: false });
      if (!error) {
        const { password, passwordConfirmation } = values;

        const passwordFormData = {
          user: {
            password: password,
            password_confirmation: passwordConfirmation,
            reset_password_token: this.state.resetPasswordToken
          }
        };

        let response;

        axios
          .put("/password.json", passwordFormData)
          .then(response => {
            this.props.history.push({
              pathname: "/login",
              state: {
                passwordCreated: true
              }
            });
          })
          .catch(error => {
            console.log("error", error);
            this.setState({ invalidPassword: true });
          });
      } else {
        console.log("error", error, values);
      }
    });
  };

  render() {
    const { getFieldError } = this.props.form;

    return (
      <div className="password-create-page">
        <div className="hs-chat-container">
          <HubspotChat />
        </div>
        <div className="body">
          <div className="container">
            <div className={styles.logoContainer}>
              <img src={papercurveLogo} />
            </div>
            <div className="setpassword-container" >
              <div className="title">
                Set your password
            </div>
              <div className="description">
                All data you enter is kept secure and private
            </div>
              <UikFormInputGroup>
                {this.passwordDecorator(
                  <UikInput
                    label="PASSWORD"
                    type="password"
                    errorMessage={(getFieldError("password") || []).join(
                      ", "
                    )}
                  />
                )}
                {this.passwordConfirmationDecorator(
                  <UikInput
                    label="CONFIRM PASSWORD"
                    type="password"
                    errorMessage={(getFieldError("passwordConfirmation") || []).join(
                      ", "
                    )}
                  />
                )}
                <Button
                  original
                  text="Set password"
                  onClick={this.handleSubmit}
                />
              </UikFormInputGroup>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const PasswordCreate = createForm()(PasswordCreateBuild);
export default PasswordCreate;
