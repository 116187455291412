import React from "react";
import "./Report.scss";

const Report = ({ reportName, id, downloadReport }) => {
    return (
        <div className="report-name" onClick={() => downloadReport(id, reportName)}>
            <span>{reportName}</span>
        </div>
    )
}

export default Report;