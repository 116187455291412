import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import TextTruncate from "react-text-truncate";
import styles from "./DocumentThumbnail.module.scss";
import { apiUrl } from "../../lib/axios-client";
import history from "../../history";

const DocumentThumbnail = ({ doc }) => {
  let linkTo = `/documents/${doc.id}`;
  if (doc.reference_document) {
    linkTo = `/references/${doc.id}`
  }

  return (
    <div className={history.location.pathname.match(/^\/drafts/) ? styles.documentContainerDark : styles.documentContainer}>
      <Link to={linkTo}>
        <div className={styles.heading}>
          <TextTruncate line={2} truncateText="..." text={doc.title} />
        </div>
        <div className={styles.createdTime}>
          {/* {console.log(doc)} */}
          {moment(doc.updated_at).calendar(null, {
            lastDay: "[Yesterday]",
            sameDay: "[Today]",
            sameElse: "MMMM Do"
          })}
        </div>
        {!doc.is_video && (
          <div
            className={styles.thumbnail}
            style={{ background: `url('${apiUrl}/documents/${doc.id}/page/1')` }}
          />
        )}

        {doc.is_video && (
          <div
            className={styles.thumbnail}
            style={{ background: `url('${doc.video_poster_url || "/assets/svg/doc-blank.svg"}')` }}
          />
        )}
      </Link>
    </div>
  )
};

DocumentThumbnail.propTypes = {
  doc: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    filename: PropTypes.string,
    thumbnail: PropTypes.string
  }).isRequired
};

export default DocumentThumbnail;
