/* External libraries */
import React from "react";
import PropTypes from "prop-types";

import { createForm, formShape } from "rc-form";
import history from "../../history";

import security from "../../services/Security";

import httpClient from "../../lib/HttpClient";
import "../../animate.css";

import notificationService from "../../services/Notifications";

/* UI Kit */
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikWidget,
  UikHeadline,
  UikDivider,
  UikCheckbox
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";
import styles from "./TagEditForm.module.css";

/* Variables */

class TagEditFormBuild extends React.Component {
  static propTypes = {
    form: formShape
  };

  constructor() {
    super();

    const currentUserRole = security.getUserRole();

    this.state = {
      currentTag: {
        role: currentUserRole
      },
      roles: [
        { value: "viewer", label: "Viewer" },
        { value: "author", label: "Author" },
        { value: "admin", label: "Admin" }
      ],
      libraries: [],
      selectedLibraries: []
    };
  }

  componentWillMount = () => {
    // Only Authors and Admins can see tags screen
    if (
      this.state.currentTag.role !== "author" &&
      this.state.currentTag.role !== "admin"
    ) {
      history.push("/drafts");
    }
  };

  handleSave(e) {
    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      //this.setState({ invalidTagEditForm: false });
      if (!error) {
        const tagFormData = {
          tag: {
            name: values[`name${this.props.tag.id}`]
          }
        };

        if (this.state.role) {
          tagFormData.tag.role = this.state.role;
          console.log(tagFormData);
        }

        httpClient
          .put(`/tags/${this.props.tag.id}.json`, tagFormData)
          .then(response => {
            this.props.loadTags();
            notificationService.addNotification(
              "Tag Saved",
              "Your changes to this tag has been saved",
              "success"
            );
          })
          .catch((e, r) => {
            let errorMessage = "";

            for (const i in e.response.data) {
              errorMessage += i + ": " + e.response.data[i];
            }

            notificationService.addNotification(
              "Tag Save Error",
              errorMessage,
              "danger"
            );
          });
      } else {
        console.log("error", error, values);
      }
    });
  }

  handleDelete(e) {
    e.preventDefault();
    const { onDelete } = this.props;
    if (
      window.confirm(
        "Are you sure you want to delete this tag? It will be removed from all documents that are tagged with it."
      )
    ) {
      httpClient.delete(`/tags/${this.props.tag.id}.json`).then(response => {
        console.log("deleted!");
        notificationService.addNotification(
          "Tag Deleted",
          "Tag has been deleted",
          "danger"
        );
        onDelete();
      });
    }
  }

  render() {
    const { tag } = this.props;
    const { getFieldError, getFieldDecorator } = this.props.form;

    return (
      <div className={styles.editTagContainer}>
          <UikWidget>
            <div className={styles.editContainer}>
              <UikHeadline>Edit Tag</UikHeadline>
            </div>
            <UikDivider />
            <div className={styles.editActionButtons}>
              <UikFormInputGroup>
                {getFieldDecorator(`name${tag.id}`, {
                  initialValue: tag.name,
                  rules: [
                    {
                      required: true,
                      message: "Please enter a name."
                    }
                  ]
                })(
                  <UikInput
                    label="NAME"
                    placeholder="tag"
                    errorMessage={(getFieldError(`name${tag.id}`) || []).join(
                      ", "
                    )}
                  />
                )}
              </UikFormInputGroup>
            </div>
            <UikDivider />
            <div className={styles.editActionButtons}>
              <UikButton
                className="floatLeft"
                onClick={e => this.handleSave(e)}
                primary
              >
                Save
              </UikButton>
              <UikButton
                className="floatRight"
                onClick={e => this.handleDelete(e)}
                error
              >
                Delete Tag
              </UikButton>
              <div className="clear" />
            </div>
          </UikWidget>
      </div>
    );
  }
}

TagEditFormBuild.propTypes = {
  onDelete: PropTypes.func.isRequired,
  tag: PropTypes.shape({
    id: PropTypes.number,
    first: PropTypes.string
  }).isRequired
};

const TagEditForm = createForm()(TagEditFormBuild);
export default TagEditForm;
