import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { createForm, formShape } from 'rc-form';

import styles from './TextareaField.module.scss'

/* Variables */
const cookies = new Cookies();

class TextareaFieldBuild extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.defaultValue
    };
  }

  componentWillReceiveProps = (nextProps) => {
    const { defaultValue } = nextProps;

    this.setState({ value: defaultValue });
  }

  handleInputChange = (e) => {
    const { name, values } = this.props;
    e.preventDefault();

    values[name] = e.target.value;

    this.setState({ value: values[name] });
  }

  render() {
    const { label, name, placeholder, required, qwer, defaultValue, values } = this.props;
    const { getFieldError, getFieldDecorator } = this.props.form;

    return (
      <div>
        <span className="uik-content-title__wrapper" style={{ 'display': 'block' }}>{`${label}`}</span>
        <div>
          <textarea
            className={`uik-input__input ${styles.metadataField}`}
            style={{ 'width': '100%' }}
            rows="5"
            value={this.state.value}
            onChange={(e) => this.handleInputChange(e)}
          ></textarea>
        </div>
      </div>
    );
  }
}

TextareaFieldBuild.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.any,
  qwer: PropTypes.any,
  values: PropTypes.object
};

const TextareaField = createForm()(TextareaFieldBuild);
export default TextareaField;
