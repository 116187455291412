import React, { useState, useEffect } from "react";
import "./Button.scss";
import Spinner from "../../../Common/Spinner";

export const Button = ({ activeText, disabledText, onClick, disabled }) => {

    const [buttonDisabled, setButtonDisabled] = useState(disabled);
    const [currentDisabledText, setCurrentDisabledText] = useState(disabledText);
    const [disabledAt, setDisabledAt] = useState(null);

    useEffect(() => {
        if (disabled) {
            setButtonDisabled(true);
            setDisabledAt(new Date());
        }
        if (!disabled && buttonDisabled) {
            const timeDiff = new Date() - disabledAt;

            if (timeDiff > 1000) {
                setButtonDisabled(false);
                setCurrentDisabledText(disabledText);
            }
            else {
                setTimeout(() => {
                    setButtonDisabled(false);
                    setCurrentDisabledText(disabledText);
                }, 1000 - timeDiff)
            }
        }
    }, [disabled])

    return (
        <button disabled={buttonDisabled} className={`button-two-state${buttonDisabled ? ` clicked` : ``}`} onClick={onClick}>
            <div className={`field active${!buttonDisabled ? ` active-shown` : ``}`}>
                {activeText}
            </div>
            <div className={`field disabled${buttonDisabled ? ` disabled-shown` : ``}`}>
                <Spinner text={currentDisabledText} />
            </div>
        </button>);
}