class Video {
  constructor() {
    this.video = null;
  }
  setVideo = video => {
    this.video = video;
    this.video.on("timeupdate", () => {
      document.dispatchEvent(
        new CustomEvent("video_timestamp_update", {
          detail: { timestamp: this.getCurrentTime() }
        })
      );
    });
  };

  getCurrentTime = () => {
    return this.video.currentTime();
  };

  setCurrentTime = currentTime => {
    this.video.currentTime(currentTime);
  };
}

export default new Video();
