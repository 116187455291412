import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Col, Row } from "react-grid-system";
import styles from "./SettingsLeftMenu.module.scss";

import security from "../../../services/Security";
import Setup2FA from "../../2FA/Setup2FA";
import httpClient from "../../../lib/HttpClient";

import papercurveLogo from "../../../images/logos/papercurve-logo-colour.svg";


class SettingsLeftMenu extends React.Component {
  constructor() {
    super();

    const currentUserRole = security.getUserRole();

    this.state = {
      currentUser: {
        role: currentUserRole
      },
      roles: [
        { value: "viewer", label: "Viewer" },
        { value: "author", label: "Author" },
        { value: "admin", label: "Admin" }
      ]
    };
  }

  render() {
    const users = window.location.pathname.match(/^\/users/) ? true : false;
    const libraries = window.location.pathname.match(/^\/libraries/) ? true : false;
    const tags = window.location.pathname.match(/^\/tags/) ? true : false;
    const activitylog = window.location.pathname.match(/^\/activitylog/) ? true : false;
    const personalinfo = window.location.pathname.match(/^\/personalinfo/) ? true : false;
    const setupsecurity = window.location.pathname.match(/^\/setupsecurity/) ? true : false;
    return (
      <Col md={2} className={styles.leftSideMenuContainer}>
        {(this.state.currentUser.role !== 'viewer') && (
          <div className={styles.leftSideMenu}>
            <div className={[styles.leftSideMenuItem, styles.header].join(" ")}>
              Workspace
            </div>
            <div
              className={`${users ? styles.selected : ""} ${styles.leftSideMenuItem}`}
            >
              <a href="/users">Users</a>
            </div>
            {this.state.currentUser.role === 'admin' && (
              <div
                className={`${libraries ? styles.selected : ""} ${
                  styles.leftSideMenuItem
                  }`}
              >
                <a href="/libraries">Libraries</a>
              </div>
            )}
            {this.state.currentUser.role === 'admin' && (
              <div
                className={`${activitylog ? styles.selected : ""} ${
                  styles.leftSideMenuItem
                  }`}
              >
                <a href="/activitylog">Activity Log</a>
              </div>
            )}
            <div
              className={`${tags ? styles.selected : ""} ${styles.leftSideMenuItem}`}
            >
              <a href="/tags">Tags</a>
            </div>
          </div>
        )}
        
        <div>
          <div className={[styles.leftSideMenuItem, styles.header].join(" ")}>
            My Account
          </div>

          <div
            className={`${personalinfo ? styles.selected : ""} ${
              styles.leftSideMenuItem
              }`}
          >
            <a href="/personalinfo">Personal Info</a>
          </div>
          <div
            className={`${setupsecurity ? styles.selected : ""} ${
              styles.leftSideMenuItem
              }`}
          >
            <a href="/setupsecurity">Security</a>
          </div>
        </div>

      </Col>
    )
  }
}

export default SettingsLeftMenu;
