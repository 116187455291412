import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styles from "./Comment.module.scss";

import deleteIcon from "../../images/icons/16px/delete2.svg";

import videoService from "../../services/Video";

const Comment = ({ comment, currentUserId, onDeleteComment, canDelete }) => (
  <div className={canDelete ? " " + styles.commentCanDelete : ""}>
    <div className={styles.commentHeader}>
      <div
        className={
          styles.commentNamePlateContainer +
          (!comment.user.active ? " " + styles.commentNamePlateDeactive : "")
        }
      >
        <span className={styles.commentNamePlate}>
          {comment.user.first_name.charAt(0)}
          {comment.user.last_name.charAt(0)}
        </span>
      </div>
      <div className={styles.nameContainer}>
        <div className={styles.commentName}>
          {comment.user.first_name} {comment.user.last_name}
          {!comment.user.active && (
            <span>
              <br />
              (Deactivated)
            </span>
          )}
        </div>
      </div>
      <div className={styles.commentHeaderRight}>
        <div className={styles.commentDate}>
          {moment(comment.commented_at).format("MMMM Do")}
        </div>
        <div className={styles.commentDelete}>
          <img
            onClick={() => {
              onDeleteComment(comment.id);
            }}
            className={styles.deleteCommentIcon}
            src={deleteIcon}
          />
        </div>
      </div>
    </div>
    <div
      className={
        currentUserId === comment.user.id
          ? styles.commentFromMe
          : styles.commentFromOthers
      }
    >
      {comment.body}
      {comment.video_timestamp != null && (
        <div
          className={styles.timestamp}
          onClick={() => {
            videoService.setCurrentTime(comment.video_timestamp);
          }}
        >
          at{" "}
          {moment()
            .startOf("day")
            .seconds(comment.video_timestamp)
            .format("H:mm:ss")}
        </div>
      )}
    </div>
  </div>
);

Comment.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  comment: PropTypes.shape({
    id: PropTypes.number,
    comment: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.string
  }).isRequired
};

export default Comment;
