/* Libraries */
import React from "react";
import { createForm } from "rc-form";
import Button from "../Common/Buttons/ButtonPrimary";
import HttpClient from "../../lib/HttpClient";
import { apiUrl } from "../../lib/axios-client";
import security from "../../services/Security";

/* UI Kit */
import { UikFormInputGroup, UikInput } from "@uik";
import "@uik/styles.css";

/* Styles */
import styles from "./PasswordSetup.module.scss";
import "./PasswordSetup.scss";

/* Assets */
import papercurveLogo from "../../images/logos/papercurve-logo-colour.svg";
import openEyeLogo from "../../images/icons/svg/open-eye.svg";
import closedEyeLogo from "../../images/icons/svg/closed-eye-100.svg";
import closedEyeLogoGrey from "../../images/icons/svg/closed-eye-75.svg";

class PasswordSetupBuild extends React.Component {
  constructor() {
    super();
    this.state = {
      invalidPassword: false
    };
  }

  componentWillMount = () => {
    this.setState({
      resetPasswordToken: this.props.match.params.token,
      documentTitle: this.props.match.params.title,
      userEmail: this.props.match.params.email,
      form: this.props.form,
      documentId: this.props.match.params.documentId,
      showError: false
    });
    this.passwordDecorator = this.props.form.getFieldDecorator("password", {
      initialValue: "",
      rules: [
        {
          required: true,
          message: "Please enter your desired password."
        },
        {
          min: 6,
          message: "Must be at least 6 characters."
        }
      ]
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ showError: true });
    this.props.form.validateFields((error, values) => {
      this.setState({ invalidPassword: false });
      if (!error) {
        const { password } = values;
        const passwordConfirmation = password
        const passwordFormData = {
          user: {
            password: password,
            password_confirmation: passwordConfirmation,
            reset_password_token: this.state.resetPasswordToken
          }
        };

        let response;

        HttpClient
          .put("/password.json", passwordFormData)
          .then(response => {
            HttpClient.post(apiUrl + "/login.json", { user: { email: this.state.userEmail, password } })
            .then(response => {
              const session = response.headers.authorization.replace(/Bearer /, "");
              const pathName = `/drafts/${this.state.documentId}/?passwordSet=${true}`
              security.setToken(session);
              security.redirectAfterSetup(pathName);
            })
          })
          .catch(error => {
            console.log("error", error);
            this.setState({ invalidPassword: true });
          });
      } else {
        console.log("error", error, values);
      }
    });
  };

  toggleViewPassword = e => {
    this.setState({ showPassword: true });
  };

  toggleHidePassword = e => {
    this.setState({ showPassword: false });
  };

  render() {
    const { getFieldError } = this.props.form;
    const closedEyeLogoActive = <img className={getFieldError("password") && this.state.showError ? styles.eyeLogoWithError : styles.eyeLogo} src={closedEyeLogo} onClick={this.toggleViewPassword} />
    const closedEyeLogoInactive = <img className={getFieldError("password") && this.state.showError ? styles.eyeLogoWithError : styles.eyeLogo} src={closedEyeLogoGrey} />
    const hidePassword = !this.state.form.getFieldValue("password") ? closedEyeLogoInactive : closedEyeLogoActive
    const showPassword = <img className={getFieldError("password") && this.state.showError ? styles.eyeLogoWithError : styles.eyeLogo} src={openEyeLogo} onClick={this.toggleHidePassword} />
    
    return (
      <div className={styles.passwordSetupPage} >
        <div className={styles.passwordSetupBackground} >
          <div className={styles.greyFilter}>
            <div className={styles.title}>{this.state.documentTitle}</div>
            <div className={styles.body}>
              <div className={styles.container}>
                <div className={styles.logoContainer}>
                  <img className={styles.papercurveLogo} src={papercurveLogo} />
                </div>
                <div className={styles.setPasswordContainer}>
                  <div className={styles.modalTitle}>
                    Before you get started, let's setup your password
                  </div>
                  <div className={styles.userEmail}>
                    {this.state.userEmail}
                  </div>
                  <div className="setupForm">
                    <UikFormInputGroup>
                      <div className={styles.eyeLogoContainer}>
                      {this.passwordDecorator(
                        <UikInput
                          label="CREATE PASSWORD"
                          type={this.state.showPassword ? "text" : "password"}
                          errorMessage={this.state.showError ? (getFieldError("password") || []).join(
                            ", "
                          ): null}
                        />
                      )}
                      {this.state.showPassword ? showPassword : hidePassword}
                      </div>
                      <Button
                        disabled={this.state.form.getFieldValue("password").length > 0 ? false : !getFieldError("password")}
                        original
                        text="Set password"
                        onClick={this.handleSubmit}
                      />
                    </UikFormInputGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const PasswordSetup = createForm()(PasswordSetupBuild);
export default PasswordSetup;
