/* External libraries */
import React from "react";
import Select from "react-select";
import { createForm, formShape } from "rc-form";
import history from "../../history";

import httpClient from "../../lib/HttpClient";

import security from "../../services/Security";
import RadioButton from '../Common/RadioButton';
import Tooltip from '../Common/Tooltip';
import Checkbox from '../Common/Checkbox';

/* UI Kit */
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikWidget,
  UikHeadline,
  UikDivider,
  UikCheckbox,
  UikToggle
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";
import styles from "./UserInviteForm.module.css";

/* Variables */

class UserInviteFormBuild extends React.Component {
  static propTypes = {
    form: formShape
  };

  constructor() {
    super();

    const currentUserRole = security.getUserRole();

    this.state = {
      invalidUserInviteForm: false,
      currentUser: {
        role: currentUserRole
      },
      roles: [
        { value: "viewer", label: "Viewer" },
        { value: "author", label: "Author" },
        { value: "admin", label: "Admin" }
      ],
      libraries: [],
      selectedLibraries: [],
      sendInviteEmail: true
    };
  }

  componentWillMount() {
    // this.setState({ invalidUserInviteForm: false });
    httpClient.get(`/libraries.json`).then(response => {
      this.setState({
        libraries: response.data
      });
    });
  }

  handleSendInvite(e) {
    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      if (!error) {
        const userFormData = {
          user: {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            title: values.title,
            library_ids: this.state.selectedLibraries,
            send_invite_email: this.state.sendInviteEmail
          }
        };

        if (this.state.role) {
          userFormData.user.role = this.state.role;
          console.log(userFormData);
        }

        this.props.handleUserInvite(userFormData);
      } else {
        console.log("error", error, values);
      }
    });
  }

  handleRoleChange = e => {
    this.setState({ role: e.target.value });
  }

  handleCancel = e => {
    history.push("/users");
  };

  userRoleSelectValue = role => {
    if (role === "viewer") {
      return this.state.roles[0];
    } else if (role === "author") {
      return this.state.roles[1];
    } else if (role === "admin") {
      return this.state.roles[2];
    }
  };

  handleLibraryChange = e => {
    if (e.target.checked) {
      // add library
      this.setState({
        selectedLibraries: [...this.state.selectedLibraries, parseInt(e.target.name)]
      });
    } else {
      // remove library
      this.setState({
        selectedLibraries: this.state.selectedLibraries.filter(
          i => i !== parseInt(e.target.name)
        )
      });
    }
  }

  handleInviteEmailSuppression = choice => {
    this.setState({
      sendInviteEmail: choice
    });
  }

  render() {
    const { getFieldError, getFieldDecorator } = this.props.form;

    return (
      <div className={styles.inviteUserContainer}>
        <UikWidget>
          <div className={styles.inviteContainer}>
            <UikHeadline>Create User</UikHeadline>
          </div>
          <UikDivider />
          <div className={styles.inviteActionButtons}>
            <UikFormInputGroup>
              {getFieldDecorator("first_name", {
                rules: [
                  {
                    required: true,
                    message: "Please enter a first name."
                  }
                ]
              })(
                <UikInput
                  label="FIRST NAME"
                  placeholder="Mary"
                  errorMessage={(getFieldError("first_name") || []).join(", ")}
                />
              )}
              {getFieldDecorator("last_name", {
                rules: [
                  {
                    required: true,
                    message: "Please enter a last name."
                  }
                ]
              })(
                <UikInput
                  label="LAST NAME"
                  placeholder="Smith"
                  errorMessage={(getFieldError("last_name") || []).join(", ")}
                />
              )}
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    message: "Please enter an email."
                  },
                  {
                    type: "email",
                    message: "Must be in the format name@company.com"
                  }
                ]
              })(
                <UikInput
                  label="EMAIL ADDRESS"
                  placeholder="you@company.com"
                  errorMessage={(getFieldError("email") || []).join(", ")}
                />
              )}
              {getFieldDecorator("title", {
                rules: [
                  {
                    required: true,
                    message: "Please enter a title."
                  }
                ]
              })(
                <UikInput
                  label="TITLE"
                  placeholder="Medical Writer"
                  errorMessage={(getFieldError("title") || []).join(", ")}
                />
              )}
              {this.state.currentUser.role === "admin" && (
                <div>
                  <div className={styles.permissionsTitle}>
                    Permissions
                </div>
                  <div className={styles.radiobuttonContainer}>
                    <RadioButton
                      name="role"
                      label="Viewer"
                      value="viewer"
                      onChange={this.handleRoleChange}
                      checked={this.state.role === "viewer"}
                    />
                    <Tooltip
                      type="permissions"
                      allowedPermissions={
                        [
                          "View Draft and Approved content",
                          "Leave comments and link references",
                          "Submit approval",
                        ]
                      }
                      disallowedPermissions={
                        [
                          "Edit list of Reviewers, and release content",
                          "Upload, edit and delete content",
                          "Add and edit users",
                          "Manage Library access and deactivate users",
                          "Add, edit and delete Libraries"
                        ]
                      }
                    />
                  </div>
                  <div className={styles.radiobuttonContainer}>
                    <RadioButton
                      name="role"
                      label="Author"
                      value="author"
                      onChange={this.handleRoleChange}
                      checked={this.state.role === "author"}
                    />
                    <Tooltip
                      type="permissions"
                      allowedPermissions={
                        [
                          "View Draft and Approved content",
                          "Leave comments and link references",
                          "Submit approval",
                          "Edit list of Reviewers, and release content",
                          "Upload, edit and delete content",
                          "Add and edit users",
                        ]
                      }
                      disallowedPermissions={
                        [
                          "Manage Library access and deactivate users",
                          "Add, edit and delete Libraries"
                        ]
                      }
                    />
                  </div>
                  <div className={styles.radiobuttonContainer}>
                    <RadioButton
                      name="role"
                      label="Admin"
                      value="admin"
                      onChange={this.handleRoleChange}
                      checked={this.state.role === "admin"}
                    />
                    <Tooltip
                      type="permissions"
                      allowedPermissions={
                        [
                          "View Draft and Approved content",
                          "Leave comments and link references",
                          "Submit approval",
                          "Edit list of Reviewers, and release content",
                          "Upload, Edit and delete content",
                          "Add and edit users",
                          "Manage Library access and deactivate users",
                          "Add, edit and delete Libraries"
                        ]
                      }
                      disallowedPermissions={
                        []
                      }
                    />
                  </div>
                </div>
              )}
              {this.state.currentUser.role === "admin" && (
                <div>
                  <div className={styles.formLabel}>LIBRARY ACCESS</div>

                  <ul>
                    {this.state.libraries.map((library, index) => (
                      <li className={styles.libraryCheckbox} key={library.id}>
                        <Checkbox
                          label={library.name}
                          name={library.id}
                          onChange={this.handleLibraryChange}
                          checked={this.state.selectedLibraries.includes(library.id)}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
                <div>
                  <div className={styles.formLabel}>WELCOME EMAIL NOTIFICATION</div>
                  <UikToggle
                    defaultChecked={this.state.sendInviteEmail} 
                    label="Send welcome email about Papercurve"
                    onChange={
                      e => this.handleInviteEmailSuppression(e.target.checked)
                    }
                  />
                  <p className={styles.welcomeEmailNote}>Disable the welcome email to add a user to Papercurve without notifying them. You can optionally send a welcome email later.</p>
                </div>
            </UikFormInputGroup>
          </div>
          <UikDivider />
          <div className={styles.inviteActionButtons}>
            <UikButton
              className="floatLeft"
              onClick={e => this.handleSendInvite(e)}
              primary
            >
              Create User
            </UikButton>
            <UikButton
              className="floatRight"
              onClick={e => this.handleCancel(e)}
            >
              Cancel
            </UikButton>
            <div className="clear" />
          </div>
        </UikWidget>
      </div>
    );
  }
}

const UserInviteForm = createForm()(UserInviteFormBuild);
export default UserInviteForm;
