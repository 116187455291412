import React from 'react';
import { UikRadio } from '@uik';

import "./RadioButton.scss";

export const RadioButton = props => {
    return (
        <UikRadio
            {...props}
        />
    )
}