import React from "react";
import PropTypes from "prop-types";
import styles from "./UserListItemsHeader.module.css";

const UserListItemsHeader = ({ letter }) => (
  <div className={styles.userListLetterHeader}>{letter}</div>
);

UserListItemsHeader.propTypes = {
  letter: PropTypes.string.isRequired
};

export default UserListItemsHeader;
