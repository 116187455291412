import React from "react";
import PropTypes from "prop-types";
import { Link, Router, Route } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import { createForm, formShape } from "rc-form";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { Line } from "rc-progress";
import sanitize from "sanitize-filename";
import CreatableSelect from 'react-select/creatable';
import notificationService from "../../../services/Notifications";

import history from "../../../history";
import axios from "../../../lib/axios-client";
import httpClient from "../../../lib/HttpClient";

import Checkmark from "../../../images/icons/png/checkmark.png";
import referenceIcon from "../../../images/icons/16px/link.svg";
import ReferenceIcon from "../../../images/icons/16px/link.svg";
import Warning from "../../../images/icons/png/warning.svg";

import security from "../../../services/Security";

import aws from "../../../services/AWS";

/* UI Kit */
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  Uikon,
  UikWidget,
  UikHeadline,
  UikHeadlineDesc,
  UikDivider,
  UikSelect,
  UikButtonGroup
} from "@uik";
import "@uik/styles.css";
import "../../../font.scss";

/* Papercurve Components */
import InputField from "../../Documents/DocumentFields/InputField";
import DropDownField from "../../Documents/DocumentFields/DropDownField";
import TextareaField from "../../Documents/DocumentFields/TextareaField";
import DateField from "../../Documents/DocumentFields/DateField";
import LibrarySelect from "../../Libraries/LibrarySelect";
import AudienceSelect from "../../Documents/DocumentFields/AudienceSelect";

import Spinner from "../../Common/Spinner";
import styles from "./DraftUploadForm.module.scss";

class DraftUploadFormBuild extends React.Component {
  constructor() {
    super();
    this.state = {
      mainDocumentPercent: 0,
      mainDocumentFilename: "",
      mainDocumentTitle: "",
      mainDocument: null,
      referenceFiles: [],
      documentFields: [],
      documentFieldValues: {},
      selectOptions: {},
      errors: {},
      error_message: "",
      error: false,
      pdfOnlyMessage:
        "Only PDF, DOC(X), XLS(X) and PPT(X) files are supported at this time.",
      referenceDocumentError: false,
      mainDocumentFileUploaded: false,
      uploadType: "document",
      tags_list: [],
      availableTags: [],
      selectedLibrary: null,
      libraryfieldError: ''
    };
  }

  componentWillMount = () => {
    httpClient.get("/document_fields.json").then(response => {
      const documentFields = response.data;
      let selectOptions = {};

      documentFields.map(df => {
        if (df.field_type === "select_options") {
          selectOptions[df.key] = df.document_field_select_options;
        }
      });

      this.setState({
        documentFields: documentFields,
        selectOptions: selectOptions
      });
    });

    httpClient.get("/tags.json").then(response => {
      const tags = [];
      response.data.map((option, index) => {
        tags.push({ value: option.name, label: option.name });
      });
      this.setState({ availableTags: tags });
    });
  };

  onMainDocumentDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles[0].type.match("video.*")) {
      this.onMainVideoDrop(acceptedFiles, rejectedFiles);
      return;
    }
    const token = security.getToken();

    const postConfig = {
      headers: {
        Authorization: "Bearer " + token
      },
      onUploadProgress: progressEvent => {
        const mainDocumentPercent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (mainDocumentPercent >= 100) {
          this.setState({ mainDocumentPercent: 100 });
        } else {
          this.setState({ mainDocumentPercent });
        }
      }
    };

    const documentFormData = new FormData();
    documentFormData.append("document[title]", this.state.mainDocumentTitle);
    documentFormData.append("document[document_file]", acceptedFiles[0]);

    this.setState({
      mainDocumentPercent: 0,
      mainDocumentFilename: acceptedFiles[0].name
    });

    axios
      .post("/documents.json", documentFormData, postConfig)
      .then(response => {
        this.setState({
          mainDocument: response.data,
          mainDocumentFileUploaded: true,
          error: false,
          errors: {},
          error_message: ""
        });
        httpClient
        .get(`/documents/${response.data.id}/metadata.json`)
        .then(response => {
          let documentFieldValues = {};

          response.data.map((metadata, idx) => {
            documentFieldValues[metadata.document_field.key] = metadata.value;
          });

          this.setState({
            documentFieldValues: documentFieldValues,
          });
        });
      })
      .catch(error => {
        security.checkAuth(error);
        console.log("error", error);
        this.setState({
          mainDocumentPercent: 0,
          mainDocument: null,
          errors: error.response.data,
          error: true
        });
      });
  };

  onLibraryChange = selectedLibrary => {
    console.log(selectedLibrary);
    this.setState({
      selectedLibrary,
      libraryfieldError: ""
    })
  };

  onMainVideoDrop = (acceptedFiles, rejectedFiles) => {
    if (!acceptedFiles[0].type.match("video.*")) {
      alert("Please upload a video file only");

      return;
    }

    httpClient.get("/aws/policy").then(response => {
      const postConfig = {
        onUploadProgress: progressEvent => {
          const mainDocumentPercent = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (mainDocumentPercent >= 100) {
            this.setState({ mainDocumentPercent: 100 });
          } else {
            this.setState({ mainDocumentPercent });
          }
        }
      };

      this.setState({
        mainDocumentPercent: 0,
        mainDocumentFilename: acceptedFiles[0].name
      });

      let data = new FormData();
      const timestamp = new Date().getTime();

      const fileName = sanitize(acceptedFiles[0].name).replace(/[{()}]/g, "");
      const fileDirectory = `${response.data.directory}/${timestamp}/`;
      const fileLocation = `${response.data.directory}/${timestamp}/${fileName}`;

      data.append("key", fileLocation);
      data.append("acl", "private");
      data.append(
        "Content-Type",
        acceptedFiles[0].type != ""
          ? acceptedFiles[0].type
          : "application/octet-stream"
      );
      data.append("X-Amz-Credential", response.data.x_amz_credential);
      data.append("X-Amz-Algorithm", response.data.x_amz_algorithm);
      data.append("X-Amz-Date", response.data.x_amz_date);
      data.append("Policy", response.data.encoded_policy);
      data.append("X-Amz-Signature", response.data.x_amz_signature);
      data.append("file", acceptedFiles[0]);

      const url = `https://${response.data.bucket}.${response.data.s3_region_endpoint}/`;

      axios
        .post(url, data, postConfig)
        .then(response => {
          let videoData = {};
          videoData.s3_url = `${url}${fileLocation}`;
          videoData.file_location = fileDirectory;
          videoData.file_name = fileName;
          videoData.file_type = acceptedFiles[0].type;
          videoData.title = this.state.mainDocumentTitle;
          httpClient
            .post("/aws/create_video", videoData)
            .then(response => {
              this.setState({
                mainDocument: response.data,
                mainDocumentFileUploaded: true,
                error: false,
                errors: {},
                error_message: ""
              });
              httpClient
              .get(`/documents/${response.data.id}/metadata.json`)
              .then(response => {
                let documentFieldValues = {};
      
                response.data.map((metadata, idx) => {
                  documentFieldValues[metadata.document_field.key] = metadata.value;
                });
      
                this.setState({
                  documentFieldValues: documentFieldValues,
                });
              });
            })
            .catch(error => {
              console.log("error", error);
              this.setState({
                mainDocumentPercent: 0,
                mainDocument: null,
                error_message:
                  "There was an error uploading your file. Ensure it is a valid video",
                error: true
              });
            });
        })
        .catch(error => {
          console.log("error", error);
          this.setState({
            mainDocumentPercent: 0,
            mainDocument: null,
            errors: ["There was an error uploading your file. "],
            error: true
          });
        });
    });
  };

  renderMainDocumentPanel = () => {
    return (
      <div>
        <span className={styles.uploaderIcon}>
          <Uikon>cloud_up</Uikon>
        </span>
        <span className={styles.uploaderPlaceholder}>
          Drop files here to upload
        </span>
        <UikButton className={styles.uploaderButton}>Browse</UikButton>
      </div>
    );
  };

  renderMainDocumentUploader = () => {
    return (
      <div className={styles.mainContentUploader}>
        <Dropzone onDrop={this.onMainDocumentDrop} multiple={false}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames("dropzone", {
                  "dropzone--isActive": isDragActive
                })}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <div>
                    <span className={styles.uploaderIcon}>
                      <Uikon>cloud_up</Uikon>
                    </span>
                    <span className={styles.uploaderPlaceholder}>
                      Drop approval document here
                    </span>
                  </div>
                ) : (
                    <div>
                      <span className={styles.uploaderIcon}>
                        <Uikon>cloud_up</Uikon>
                      </span>
                      <span className={styles.uploaderPlaceholder}>
                        Drop files here to upload
                    </span>
                      <UikButton className={styles.uploaderButton}>
                        Browse
                    </UikButton>
                    </div>
                  )}
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  };

  renderMainVideoUploader = () => {
    return (
      <div className={styles.mainContentUploader}>
        <Dropzone onDrop={this.onMainVideoDrop} multiple={false}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames("dropzone", {
                  "dropzone--isActive": isDragActive
                })}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <div>
                    <span className={styles.uploaderIcon}>
                      <Uikon>cloud_up</Uikon>
                    </span>
                    <span className={styles.uploaderPlaceholder}>
                      Drop approval document here
                    </span>
                  </div>
                ) : (
                    <div>
                      <span className={styles.uploaderIcon}>
                        <Uikon>cloud_up</Uikon>
                      </span>
                      <span className={styles.uploaderPlaceholder}>
                        Drop files here to upload
                    </span>
                      <UikButton className={styles.uploaderButton}>
                        Browse
                    </UikButton>
                    </div>
                  )}
              </div>
            );
          }}
        </Dropzone>
        <div
          id="videoUploaderProgress"
          style={this.state.videoUploaderStyle}
          className={styles.videoUploaderProgress}
        ></div>
      </div>
    );
  };

  renderMainDocumentUploaderProgress = () => {
    return (
      <div className={styles.mainContentUploading}>
        <div className={styles.innerContentUploading}>
          <span className={styles.uploaderIcon}>
            <Uikon>attachment</Uikon>
          </span>

          {this.state.error && (
            <span className={styles.uploadError}>
              {this.state.error_message
                ? this.state.error_message
                : this.state.pdfOnlyMessage}
            </span>
          )}

          <span className={styles.uploadingFilename}>
            {this.state.mainDocumentFilename}
          </span>
          {this.state.mainDocumentPercent == 100 && !this.state.error && (
            <span className={styles.uploaderIconCheck}>
              <img src={Checkmark} className={styles.checkMark} />
            </span>
          )}
          {this.state.error && (
            <span className={styles.uploaderIconError}>
              <img src={Warning} className={styles.warning} />
            </span>
          )}

          {this.state.mainDocumentPercent < 100 && (
            <Line
              className={styles.uploadProgress}
              percent={this.state.mainDocumentPercent}
              strokeWidth="1"
              strokeColor="#1b8c96"
              strokeLinecap="square"
            />
          )}
        </div>
      </div>
    );
  };

  onReferenceDocumentsDrop = (acceptedFiles, rejectedFiles) => {
    const token = security.getToken();

    this.setState({
      referenceFiles: this.state.referenceFiles.concat(acceptedFiles)
    });

    acceptedFiles.map((referenceFile, index) => {
      const pctIndex = this.state.referenceFiles.length + index;

      const postConfig = {
        headers: {
          Authorization: "Bearer " + token
        },
        onUploadProgress: progressEvent => {
          const referenceFilePercent = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          if (referenceFilePercent >= 100) {
            this.setState({ ["referenceFilePercentages" + pctIndex]: 100 });
          } else {
            this.setState({
              ["referenceFilePercentages" + pctIndex]: referenceFilePercent
            });
          }
        }
      };

      this.setState({ ["referenceFilePercentages" + pctIndex]: 0 });

      const referenceFormData = new FormData();
      referenceFormData.append("document[title]", referenceFile.name);
      referenceFormData.append("document[document_file]", referenceFile);
      referenceFormData.append(
        "document[reference_document_id]",
        this.state.mainDocument.id
      );
      referenceFormData.append("document[reference_document]", true);

      axios
        .post("/documents.json", referenceFormData, postConfig)
        .then(response => {
          this.setState({ referenceDocumentError: false, errors: {} });
        })
        .catch(error => {
          security.checkAuth(error);
          console.log("error", error);
          this.setState({
            ["referenceFilePercentages" + pctIndex]: 0,
            ["referenceFileError" + pctIndex]: true,
            errors: error.response.data,
            referenceDocumentError: true
          });
        });
    });
  };

  renderReferenceDocumentsUploader = () => {
    return (
      <div className={styles.referenceContentUploader}>
        <Dropzone onDrop={this.onReferenceDocumentsDrop} multiple={true}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames("dropzone", {
                  "dropzone--isActive": isDragActive
                })}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <div>
                    <span className={styles.uploaderIcon}>
                      <Uikon>cloud_up</Uikon>
                    </span>
                    <span className={styles.uploaderPlaceholder}>
                      Drop reference documents here
                    </span>
                  </div>
                ) : (
                    <div>
                      <span className={styles.uploaderIcon}>
                        <Uikon>cloud_up</Uikon>
                      </span>
                      <span className={styles.uploaderPlaceholder}>
                        Drop files here to upload
                    </span>
                      <UikButton className={styles.uploaderButton}>
                        Browse
                    </UikButton>
                    </div>
                  )}
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  };

  renderReferenceDocumentsUploaderProgress = (rf, idx) => {
    const rfProgressPercentage = this.state["referenceFilePercentages" + idx];
    return (
      <div className={styles.referenceContentUploading}>
        <div>
          <span className={styles.uploaderIcon}>
            <Uikon>attachment</Uikon>
          </span>
          {this.state["referenceFileError" + idx] && (
            <span className={styles.uploadError}>
              {this.state.pdfOnlyMessage}
            </span>
          )}

          <span className={styles.uploadingFilename}>{rf.name}</span>
          {rfProgressPercentage == 100 && (
            <span className={styles.uploaderIconCheck}>
              <img src={Checkmark} className={styles.checkMark} />
            </span>
          )}
          {this.state["referenceFileError" + idx] &&
            <span className={styles.uploaderIconError}>
              <img src={Warning} className={styles.warning} />
            </span>
          }

          {rfProgressPercentage < 100 && (
            <Line
              className={styles.uploadProgress}
              percent={rfProgressPercentage}
              strokeWidth="1"
              strokeColor="#1b8c96"
              strokeLinecap="square"
            />
          )}
        </div>
      </div>
    );
  };

  renderDocumentFields = () => {
    const { getFieldError, getFieldDecorator } = this.props.form;
    const customCreatableSelectStyles = {
      control: base => ({
        ...base,
        maxHeight: 100,
        overflow: 'auto'
      })
    };

    return (
      <div className={styles.metadataContainer}>
        <div className={styles.metadataSingleLineInputFields}>
          <div className={styles.librarySelect}>
            <LibrarySelect
              onLibraryChange={this.onLibraryChange}
              readOnly={false}
              error={this.state.libraryfieldError}
            />
          </div>
          {this.state.documentFields.map((field, idx) => {
            if (field.field_type === "sequential") {
              return (
                <div className={styles.textMetadataField}>
                  <span className={styles.metadataFieldLabel}>{field.label}</span>
                  <span className={styles.metadataFieldValue}>{this.state.documentFieldValues[field.key]}</span>
                </div>
              )
            } else if (field.field_type === "input") {
              return (
                <div key={field.key} data-cy={field.key}>
                  <InputField
                    label={field.label}
                    name={field.key}
                    required={field.required}
                    values={this.state.documentFieldValues}
                  />
                </div>
              );
            } else if (field.field_type === "select_options") {
              return (
                <div>
                  <AudienceSelect
                    label={field.label}
                    name={field.key}
                    options={this.state.selectOptions[field.key]}
                    values={this.state.documentFieldValues}
                  />
                </div>
              );
            } else if (field.field_type === "date_time") {
              return (
                <div key={field.key} data-cy={field.key}>
                  <DateField
                    label={field.label}
                    name={field.key}
                    required={field.required}
                    values={this.state.documentFieldValues}
                  />
                </div>
              );
            }
          })}
        </div>
        <div className={styles.metadataMultilineInputFields}>
          {this.state.documentFields.map((field, idx) => {
            if (field.field_type === "textarea") {
              return (
                <div key={field.key} data-cy={field.key}>
                  <TextareaField
                    label={field.label}
                    name={field.key}
                    required={field.required}
                    values={this.state.documentFieldValues}
                  />
                </div>
              );
            }
          })}
          <span class="uik-content-title__wrapper">Tags</span>
          <CreatableSelect
            isMulti
            onChange={this.handleChangeCreatableSelect}
            styles={customCreatableSelectStyles}
            options={this.state.availableTags}
          />
        </div>
      </div>
    );
  };

  handleChangeCreatableSelect = (newValue: any, actionMeta: any) => {
    let tagsList = [];

    if (newValue) {
      newValue.forEach((tag) => {
        tagsList.push(tag.value);
      });
    }

    this.setState({ tags_list: tagsList });
  };

  goToDocument = e => {
    e.preventDefault();
    history.push(`/drafts/${this.state.mainDocument.id}`);
  };

  goToDrafts = () => {
    history.push("/drafts");
  };

  saveTitle = e => {
    let documentFormData = {};

    documentFormData.title = this.state.mainDocumentTitle;

    httpClient
      .put(`/documents/${this.state.mainDocument.id}.json`, documentFormData)
      .then(response => {
        this.setState({ mainDocument: response.data });

        documentFormData = {};
        documentFormData.tags_list = this.state.tags_list;

        httpClient
          .post(
            `/documents/${this.state.mainDocument.id}/tags.json`,
            documentFormData
          )
          .then(response => {
            this.saveMetadata(e);
          });
      });
  };

  saveMetadata = e => {
    const documentFieldsData = {};

    this.state.documentFields.map((field, idx) => {
      documentFieldsData[field.key] = this.state.documentFieldValues[field.key];
    });

    // Save the metadata values.
    httpClient
      .post(
        `/documents/${this.state.mainDocument.id}/document_field_values.json`,
        { document_field_values: documentFieldsData }
      )
      .then(response => {
        // Only if successfully saved can we hit next.
        this.goToDocument(e);
      });
  };

  handleNext = e => {
    e.preventDefault();

    if (this.state.selectedLibrary === null) {
      this.setState({ libraryfieldError: "Choose a library to continue" });

      notificationService.addNotification(
        "Choose a library.",
        "Choose a library to continue",
        "warning"
      );
    } else {
      const documentFormData = {
        document: {
          library_id: this.state.selectedLibrary.value
        }
      };
      httpClient
        .put(`/documents/${this.state.mainDocument.id}.json`, documentFormData)
        .then(() => {
          this.saveTitle(e);
        });
    }
  };

  onUploadTypeChange = type => {
    this.setState({ uploadType: type });
  };

  render() {
    const { getFieldError, getFieldDecorator } = this.props.form;

    return (
      <div>
        <Container fluid className={styles.draftUploadForm}>
          <UikFormInputGroup>
            <span>Content Name</span>
            {getFieldDecorator("title", {
              rules: [
                {
                  required: true,
                  message: "Please enter a title."
                }
              ]
            })(
              <UikInput
                onChange={e =>
                  this.setState({ mainDocumentTitle: e.target.value })
                }
                placeholder="Type the name of your content"
                errorMessage={(getFieldError("title") || []).join(", ")}
              />
            )}

            {this.state.mainDocumentTitle && (
              <div className={styles.formatNoteContainer}>
                <span>Upload your main content</span>
                <p className={styles.formatNote}>You can upload PDF, DOCX, XLSX, PPTX, and Videos</p>
              </div>
            )}

            {this.state.uploadType == "video" &&
              this.state.mainDocumentTitle &&
              !this.state.mainDocumentFilename &&
              this.renderMainVideoUploader()}

            {this.state.uploadType == "document" &&
              this.state.mainDocumentTitle &&
              !this.state.mainDocumentFilename &&
              this.renderMainDocumentUploader()}

            {this.state.mainDocumentFilename.length > 0 &&
              this.renderMainDocumentUploaderProgress()}

            {this.state.mainDocumentPercent == 100 &&
              this.renderDocumentFields()}
          </UikFormInputGroup>
        </Container>
        <Container fluid className={styles.draftUploadFormActions}>
          {this.state.mainDocument && this.state.mainDocumentPercent == 100 && (
            <UikButton primary onClick={e => this.handleNext(e)}>
              Next
            </UikButton>
          )}
          {!this.state.mainDocument && this.state.mainDocumentPercent == 100 && (
            <div className={styles.processingContainer}>
              <Spinner text={"Processing..."} />
            </div>
          )}
        </Container>
      </div>
    );
  }
}

const DraftUploadForm = createForm()(DraftUploadFormBuild);
export default DraftUploadForm;
