/* External libraries */
import React from "react";
import Select from "react-select";

import httpClient from "../../lib/HttpClient";
import security from "../../services/Security";
import "./LibrarySelect.scss";

/* Variables */

class LibrarySelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      libraries: [],
      selectedLibrary: {
        value: this.props.library_id,
        label: this.props.library_name
      }
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount = () => {
    const currentUserId = security.getUserId();
    httpClient.get(`/users/${currentUserId}/libraries.json`).then(response => {
      this.setState({
        libraries: this.transformData(response.data)
      });
    });
  };

  transformData = data => {
    let orderedLibraryNames = data.map(library => (library.name)).sort();
    let orderedLibraries = []
    for (const name of orderedLibraryNames) {
      for (const library of data) {
        if (name === library.name) {
          orderedLibraries.push({ value: library.id, label: library.name })
        }
      }
    }
    return orderedLibraries
  };

  onChange = selectedLibrary => {
    this.setState({
      selectedLibrary
    });
    this.props.onLibraryChange(selectedLibrary);
  };

  render() {
    let className = this.props.error ? `library_select library_select-error` : `library_select`;

    return (
      <div className={className}>
        <span className="uik-content-title__wrapper">LIBRARY</span>
        <Select
          placeholder="Select a Library"
          value={
            this.state.selectedLibrary.value ? this.state.selectedLibrary : null
          }
          options={this.state.libraries}
          onChange={e => this.onChange(e)}
          isDisabled={this.props.readOnly}
          styles={{ control: styles => ({ ...styles, borderColor: '#D6DCE8', fontSize: '14px' }) }}
        />
        {this.props.error &&
          <p class="uik-input__errorMessage">{this.props.error}</p>
        }
      </div>
    );
  }
}

export default LibrarySelect;
