import React from "react";
import PropTypes from "prop-types";
import { Link, Router, Route } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";

import styles from "./Drafts.module.scss";
import history from "../../history";
import httpClient from "../../lib/HttpClient";

import security from "../../services/Security";

/* UI Kit */
import { UikButton } from "@uik";
import "@uik/styles.css";
import "../../font.scss";

/* Papercurve Components */
import Header from "../shared/Header/Header";
import DraftListItem from "./DraftListItem";

/* Variables */

class Drafts extends React.Component {
  constructor() {
    super();

    const currentUserRole = security.getUserRole();

    this.state = {
      approvals: [],
      inProgress: [],
      currentUser: {
        role: currentUserRole
      }
    };
  }

  componentWillMount = () => {
    const userId = security.getUserId();

    this.setState({ userId });

    httpClient.get(`/users/${userId}/pending_action.json`).then(response => {
      this.setState({ approvals: response.data });
    });

    httpClient.get(`/users/${userId}/in_progress.json`).then(response => {
      this.setState({ inProgress: response.data });
    });
  };

  uploadDraft = e => {
    e.preventDefault();
    history.push("/drafts/upload");
  };

  render() {
    return (
      <Container fluid>
        <Header />
        <Row className={styles.draftsHome}>
          <Col sm={1} />
          <Col sm={5}>
            <div className={styles.draftsHeading}>
              <h1 className="main-headline">My Tasks</h1>
              <span>
                The content you are authoring or needing your approval.
              </span>
            </div>
            <div className={styles.draftsHomeLists}>
              {this.state.approvals.map((approval, index) => {
                return <DraftListItem approval={approval} key={index} />;
              })}
              {this.state.approvals.length === 0 && (
                <em className={styles.empty}>No current tasks.</em>
              )}
            </div>
          </Col>
          <Col sm={5}>
            <div className={styles.draftsHeading}>
              <div className={styles.inProgressHeading}>
                <h1 className="main-headline">Approvals in progress</h1>
                <span>Other team members are reviewing this content.</span>
              </div>
              {this.state.currentUser.role !== "viewer" && (
                <UikButton
                  className={styles.uploadAction}
                  onClick={e => this.uploadDraft(e)}
                >
                  Upload
                </UikButton>
              )}
              <div className="clear" />
            </div>
            <div className={styles.draftsHomeLists}>
              {this.state.inProgress.map((approval, index) => {
                return <DraftListItem approval={approval} key={index} />;
              })}
              {this.state.inProgress.length === 0 && (
                <em className={styles.empty}>
                  No current approvals in progress.
                </em>
              )}
            </div>
          </Col>
          <Col sm={1} />
        </Row>
      </Container>
    );
  }
}

export default Drafts;
